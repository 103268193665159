import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  notifyMessage: string | null = null;
  successMessage: string | null = null;
  errorMessage: string | null = null;

  private timeoutDuration = 2000; // 2 seconds
  private timeoutDurationforsubmitchange = 3000;

  // Method to show general notification and auto-clear after 3 seconds
  showNotification(message: string) {
    this.notifyMessage = message;
    this.autoClearNotification();
  }

  // Method to show success notification and auto-clear after 3 seconds
  showSuccess(message: string) {
    this.successMessage = message;
    this.autoClearSuccess();
  }

  // Method to show error notification and auto-clear after 3 seconds
  showError(message: string) {
    this.errorMessage = message;
    this.autoClearError();
  }

  // Method to clear all notifications
  clearMessages() {
    this.notifyMessage = null;
    this.successMessage = null;
    this.errorMessage = null;
  }

  // Automatically clear the general notification after 3 seconds
  private autoClearNotification() {
    setTimeout(() => {
      this.notifyMessage = null;
    }, this.timeoutDuration);
  }

  // Automatically clear the success notification after 3 seconds
  private autoClearSuccess() {
    setTimeout(() => {
      this.successMessage = null;
    }, this.timeoutDuration);
  }

  // Automatically clear the error notification after 3 seconds
  private autoClearError() {
    setTimeout(() => {
      this.errorMessage = null;
    }, this.timeoutDuration);
  }
}
