<!-- <mat-sidenav-container> -->
<!-- <mat-sidenav #sidenav mode="side" opened>
    <app-sidebar [drawer]="sidenav"></app-sidebar>
  </mat-sidenav> -->
<!-- </mat-sidenav-content>
</mat-sidenav-container> -->
<!-- <mat-sidenav-content> -->
<mat-toolbar class="">
  <header>
    <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand d-flex align-items-center p-0">
          <img src="../../../assets/images/MSuite-Logo.png" class="img-fluid" alt="MSuite" />
          <span class="divider"></span>
        </a>
        <!-- <span class="navbar-brand d-flex align-items-center p-0">{{ pageTitle }}</span> -->

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <!-- <ng-container *ngFor="let actionButton of actionButtons; trackBy: trackByFn">
                <li class="nav-item">
                  <button
                    class="btn btn-sm"
                    [ngClass]="{
                      'btn-primary': actionButton.type === 'primary',
                      'btn-outline-primary': actionButton.type === 'secondary',
                    }"
                    (click)="handleOnClick(actionButton)"
                    [disabled]="actionButton.isDisabled">
                    {{ actionButton.name }}
                  </button>
                </li>
              </ng-container> -->
          </ul>
        </div>
        <div class="dropdown mx-5">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false">
            Checklists
          </button>
          <ul class="dropdown-menu w-35 p-3" aria-labelledby="dropdownMenuButton">
            <li>
              <a class="dropdown-item" href="/#/checklist?d=allloans">
                <h6>All Loans</h6>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="/#/checklist?d=classification">
                <h6>Classification</h6>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="/#/checklist?d=dataextraction">
                <h6>Data Extraction</h6>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="/#/checklist?d=rulesengine">
                <h6>Rules Engine</h6>
              </a>
            </li>
          </ul>
        </div>
        <!-- Button to fetch customer list -->
        <!-- <div class="position-relative">
            <button class="btn btn-sm btn-outline-primary ms-3" (click)="toggleCustomerList()">
              <i class="fas fa-users"></i>
            </button>
    
           
            <div *ngIf="isCustomerListVisible" class="dropdown-menu show mt-2">
              <ul class="list-group">
                <li
                  *ngFor="let customer of customerList; let i = index"
                  class="list-group-item"
                  [ngClass]="{ highlighted: i === highlightedCustomerIndex }"
                  (click)="onCustomerClick(customer, i)"
                  (keyup.enter)="onCustomerClick(customer, i)"
                  (keydown.enter)="onCustomerClick(customer, i)"
                  tabindex="0">
                  {{ customer.name }}
                </li>
              </ul>
            </div>
          </div> -->
        <!-- <button (click)="toggleProfilePopup()" class="btn btn-sm btn-outline-primary ms-3">
            <i class="fas fa-user"></i>
          </button> -->
        <!-- <div *ngIf="isProfilePopupVisible" class="profile-popup">
            <div class="usermenu">
              <div class="username">
                <strong>{{ name }}</strong>
              </div>
              <div class="email">{{ userEmail }}</div>
            </div>
    
            <div
              (click)="logout()"
              class="logout-link cursor-pointer"
              (keydown.enter)="logout()"
              (keydown.space)="logout()"
              tabindex="0">
              Logout
            </div>
          </div> -->
        <!-- Confirmation Popup -->
        <!-- <div *ngIf="isConfirmationVisible" class="confirmation-popup">
            <div class="confirmation-content">
              <p>Are you sure want to switch the customer?</p>
              <div class="confirmation-buttons">
                <button class="btn btn-primary" (click)="confirmCustomerSwitch()">Yes</button>
                <button class="btn btn-secondary" (click)="cancelCustomerSwitch()">No</button>
              </div>
            </div>
          </div> -->
      </div>
    </nav>
  </header>
</mat-toolbar>
<router-outlet />
<!-- <footer class="footer-main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <span class="me-1">© Copyright</span>
          <img src="../../../assets/images/fws-logo.png" class="img-fluid" alt="Flatworld Solutions" />
        </div>
        <div class="col-6 text-end">
          <span class="text-right me-1">Powered by</span>
          <img src="../../../assets/images/logo-rev.png" class="img-fluid" alt="Revelaition" />
        </div>
      </div>
    </div>
  </footer> -->
