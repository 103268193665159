<header>
  <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a [href]="location" class="navbar-brand d-flex align-items-center p-0">
        <img src="../../../assets/images/MSuite-Logo.png" class="img-fluid" alt="MSuite" />
        <span class="divider"></span>
      </a>
      <span class="navbar-brand d-flex align-items-center p-0">{{ pageTitle }}</span>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <ng-container *ngFor="let actionButton of actionButtons; trackBy: trackByFn">
            <li class="nav-item">
              <button
                class="btn btn-sm"
                [ngClass]="{
                  'btn-primary': actionButton.type === 'primary',
                  'btn-outline-primary': actionButton.type === 'secondary',
                }"
                (click)="handleOnClick(actionButton)"
                [disabled]="actionButton.isDisabled">
                {{ actionButton.name }}
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="dropdown mx-5">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false">
          Checklists
        </button>
        <ul class="dropdown-menu w-35 p-3" aria-labelledby="dropdownMenuButton">
          <li>
            <a class="dropdown-item" [ngClass]="{ active: selectedPage === 'allloans' }" href="/#/checklist?d=allloans">
              <h6 class="mt-2">All Loans</h6>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              [ngClass]="{ active: selectedPage === 'classification' }"
              href="/#/checklist?d=classification">
              <h6 class="mt-2">Classification</h6>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              [ngClass]="{ active: selectedPage === 'dataextraction' }"
              href="/#/checklist?d=dataextraction">
              <h6 class="mt-2">Data Extraction</h6>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              [ngClass]="{ active: selectedPage === 'rulesengine' }"
              href="/#/checklist?d=rulesengine">
              <h6 class="mt-2">Rules Engine</h6>
            </a>
          </li>
        </ul>
      </div>
      <div class="position-relative dropdown">
        <button
          class="btn btn-sm btn-outline-primary ms-3 dropdown-toggle"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
          (click)="toggleCustomerList($event)">
          <i class="fas fa-users"></i>
        </button>

        <ul *ngIf="isCustomerListVisible" class="dropdown-menu show mt-2 list-group">
          <li
            *ngFor="let customer of userDetails; let i = index"
            class="list-group-item"
            [ngClass]="{ highlighted: customer.customerId === currentCustomer }"
            (click)="onCustomerClick(customer, customer.customerId)"
            (keyup.enter)="onCustomerClick(customer, customer.customerId)"
            (keydown.enter)="onCustomerClick(customer, customer.customerId)"
            tabindex="0">
            {{ customer.name }}
          </li>
        </ul>
      </div>
      <button (click)="toggleProfilePopup($event)" class="btn btn-sm btn-outline-primary ms-3">
        <i class="fas fa-user"></i>
      </button>
      <div *ngIf="isProfilePopupVisible" class="profile-popup">
        <div class="usermenu">
          <div class="username">
            <strong>{{ name }}</strong>
          </div>
          <div class="email">{{ userEmail }}</div>
        </div>

        <div
          (click)="logout()"
          class="logout-link cursor-pointer"
          (keydown.enter)="logout()"
          (keydown.space)="logout()"
          tabindex="0">
          Logout
        </div>
      </div>
      <!-- Confirmation Popup -->
      <div *ngIf="isConfirmationVisible" class="confirmation-popup">
        <div class="confirmation-content">
          <p>Are you sure want to switch the customer?</p>
          <div class="confirmation-buttons">
            <button class="btn btn-primary" (click)="confirmCustomerSwitch()">Yes</button>
            <button class="btn btn-secondary" (click)="cancelCustomerSwitch()">No</button>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div *ngIf="isLoading" class="spinner-container container-fluid">
    <p-progressSpinner ariaLabel="loading" />
    <p>Switching ...</p>
  </div>
</header>
