import { Component, ElementRef, HostListener, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { ApplicationRoute, RouteTo } from "src/app/app-routing.module";
import { CustomerDetailsService, UserDetails } from "src/app/service/CustomerDetails/customer-details.service";
import { TokenStorageService } from "src/app/service/TokenStorageService/token-storage.service";
import { v4 as uuidv4 } from "uuid";
export interface PageActionButton {
  name: string;
  type: "primary" | "secondary";
  onClick: () => void;
  isDisabled: boolean;
}

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.css"],
})
export class PageHeaderComponent implements OnInit {
  @Input() actionButtons: PageActionButton[] = [];
  @Input() pageTitle = "";
  @Input() selectedPage = "";
  isLogOut = false;
  location = "";
  isProfilePopupVisible = false;
  name: string | null = "";

  userEmail = "";
  isCustomerDropdownVisible = false;
  selectedCustomer: UserDetails | null = null;
  isConfirmationVisible = false;
  pendingCustomer: UserDetails | null = null;
  highlightedCustomerIndex: number | null = null;
  defaultCustomerName = "";
  showCustomerDropdown = false;
  isCustomerListVisible = false;

  userDetails: UserDetails[] = [];

  currentCustomer = 0;

  isLoading = false;

  constructor(
    private msalService: MsalService,
    private router: Router,
    private customerDetailsService: CustomerDetailsService,
    private tokenStorageService: TokenStorageService,
    private elementRef: ElementRef,
    @Inject(MSAL_GUARD_CONFIG) private msalConfig: MsalGuardConfiguration
  ) {}

  ngOnInit(): void {
    this.location = this.getLocationBasedOnUrl(window.location.href);
    this.name = this.tokenStorageService.getUsername();
    this.customerDetailsService.getUserContextUpdate().subscribe({
      next: (response) => {
        this.currentCustomer = response.currentCustomerId;
      },
      error: (err) => {
        console.error(err);
      },
    });
    this.fetchCustomerList();
    this.currentCustomer = this.tokenStorageService.getUserCustomerId();
  }

  toggleProfilePopup(event: Event) {
    event?.stopPropagation();
    this.isProfilePopupVisible = !this.isProfilePopupVisible;
    if (this.isCustomerListVisible) {
      this.isCustomerListVisible = false;
    }
    if (this.isConfirmationVisible) {
      this.isConfirmationVisible = false;
    }
  }

  logout() {
    localStorage.removeItem("isUserLoggedIn");
    localStorage.removeItem("isTransactionReviewScreenActive");
    localStorage.removeItem("transactionFilters");
    localStorage.removeItem("transactionSorting");
    this.tokenStorageService.clearStorage();
    this.msalService.logoutPopup().subscribe(() => {
      window.sessionStorage.clear();
      window.localStorage.clear();

      this.router.navigate(["/"], { replaceUrl: true });

      setTimeout(() => {
        window.history.pushState(null, "", "/");
      }, 0);
    });
  }

  private getLocationBasedOnUrl(currentUrl: string): string {
    if (currentUrl.includes("indemo")) {
      return "https://bsic.indemo.msuite.ai/";
    } else if (currentUrl.includes("usdemo")) {
      return "https://bsic.usdemo.msuite.ai/";
    }
    return "";
  }

  handleOnClick(action: PageActionButton) {
    if (!action.isDisabled) {
      action.onClick();
    }
    localStorage.removeItem("transactionFilters");
    localStorage.removeItem("transactionSorting");
  }

  trackByFn(index: number): number {
    return index;
  }

  toggleCustomerList(event: Event): void {
    event?.stopPropagation();
    this.currentCustomer = this.tokenStorageService.getUserCustomerId();
    this.isCustomerListVisible = !this.isCustomerListVisible;
    if (this.isProfilePopupVisible) {
      this.isProfilePopupVisible = false;
    }
    if (this.isConfirmationVisible) {
      this.isConfirmationVisible = false;
    }
  }

  fetchCustomerList(): void {
    this.customerDetailsService.getUserCustomers().subscribe({
      next: (response) => {
        this.userDetails = response;
        if (this.userDetails.length > 0) {
          this.userEmail = this.userDetails[0].userEmail;
        }
      },
      error: (response) => {
        console.error("Error fetching user details", response);
      },
    });
  }

  confirmCustomerSwitch(): void {
    if (this.pendingCustomer) {
      this.isLoading = true;
      console.log("Switching to Customer ID:", this.pendingCustomer.customerId);

      this.customerDetailsService.getUserContextUpdate(this.pendingCustomer.customerId).subscribe({
        next: (response) => {
          this.isConfirmationVisible = false;
          console.log("Successfully Switched to Customer ID:", response.currentCustomerId);
          this.tokenStorageService.updateCustomerId(response.currentCustomerId, response.userRole);
          if (this.pendingCustomer) {
            this.startNewSession(this.pendingCustomer.name);
          }
          this.router.navigate([RouteTo(ApplicationRoute.Landing)]);
          this.isLoading = false;
          this.currentCustomer = response.currentCustomerId;
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
        },
      });
      this.isCustomerListVisible = false;
    }
  }

  cancelCustomerSwitch(): void {
    this.pendingCustomer = null;
    this.isConfirmationVisible = false;
    this.isCustomerListVisible = false;
  }

  onCustomerClick(user: UserDetails, index: number): void {
    if (index === this.currentCustomer) {
      //  this.isConfirmationVisible = false;
    } else {
      this.pendingCustomer = user;
      this.isConfirmationVisible = true;
    }
    //  this.isCustomerListVisible = false;
  }

  generateSessionId(): string {
    return uuidv4();
  }

  startNewSession(customer: string): void {
    const sessionData = {
      selectedCustomer: customer,
      sessionStart: new Date().toISOString(),
      sessionId: this.generateSessionId(),
    };

    const sessionDataString = JSON.stringify(sessionData);
    localStorage.setItem("sessionData", sessionDataString);
    sessionStorage.setItem("sessionData", sessionDataString);
    console.log("Stored session data in localStorage and sessionStorage:", sessionData);
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(".dropdown");
    const clickedInsideProfilePopup = (event.target as HTMLElement).closest(".profile-popup");
    const clickedInsideChecklist = (event.target as HTMLElement).closest('[aria-labelledby=".dropdownMenuButton"]');
    if (!clickedInsideDropdown) {
      this.isCustomerListVisible = false;
      if (this.isConfirmationVisible) {
        this.isConfirmationVisible = false;
      }
    }
    if (!clickedInsideProfilePopup) {
      this.isProfilePopupVisible = false;
    }
    if (!clickedInsideChecklist) {
      this.isCustomerListVisible = false;
      this.isProfilePopupVisible = false;
    }
  }
}
