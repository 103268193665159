import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { AngularMaterialModule } from "../../material/material.module";

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [CommonModule, AngularMaterialModule],
  templateUrl: "./navbar.component.html",
  styleUrl: "./navbar.component.scss",
})
export class NavbarComponent {}
