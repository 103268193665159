import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AngularMaterialModule } from "../../material/material.module";

@Component({
  selector: "app-admin-layout",
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, RouterModule],
  templateUrl: "./admin-layout.component.html",
  styleUrl: "./admin-layout.component.scss",
})
export class AdminLayoutComponent {}
