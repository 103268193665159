import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { LoanHeaderDetails } from "src/app/models/loans";
import { FileListGrid } from "src/app/models/statement-summary";
import { BankStatementExtractionService } from "src/app/service/BankStatementExtractionService/bank-statement-extraction.service";

export interface OpenChangeEvent {
  isOpen?: boolean;
}
@Component({
  selector: "app-upload-document-from-storage-modal",
  templateUrl: "./upload-document-from-storage-modal.component.html",
  styleUrls: ["./upload-document-from-storage-modal.component.css"],
})
export class UploadDocumentFromStorageModalComponent implements OnChanges {
  @Input() loanId: string | undefined;
  @Input() isOpen = false;
  @Input() multiSelect = false;
  @Output() emitOpenChange = new EventEmitter<{ isOpen?: boolean }>();
  @Output() selectFileEvent = new EventEmitter<FileListGrid[]>();

  @ViewChild("fileInput") fileInput!: ElementRef;

  loanHeaderDetails: LoanHeaderDetails | undefined;

  fileListGrid: FileListGrid[] = [];

  fileToUpload: File[] = [];
  fileNameToUpload: string[] = [];
  selectedFiles: FileListGrid[] = [];
  folderName = "-";
  uploadInProgress = false;
  allSelected = false;

  toastObj = {
    timeOut: 2000,
    progressBar: true,
    positionClass: "toast-top-center",
  };

  constructor(
    private bankStatementExtractionService: BankStatementExtractionService,
    private toaster: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["loanId"] && changes["loanId"].currentValue !== changes["loanId"].previousValue) {
      this.initiateData();
    }
  }

  initiateData() {
    this.fileToUpload = [];
    this.fileNameToUpload = [];
    this.selectedFiles = [];
    this.fileListGrid = [];
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
    this.fetchLoanData();
    this.fetchFilesByLoanId();
  }

  fetchLoanData() {
    if (!this.loanId) return;
    this.bankStatementExtractionService.getLoanHeaderDetailsById(this.loanId).subscribe((response) => {
      if (response.success) {
        this.loanHeaderDetails = response.data;
      }
    });
  }

  fetchFilesByLoanId(onCallBack?: () => void) {
    if (!this.loanId) return;
    this.bankStatementExtractionService.getFilesByLoanId(this.loanId).subscribe((response) => {
      if (response.success) {
        this.fileListGrid = [];
        this.fileListGrid = response.data.sort(
          (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        );
        this.setFolderName();
        if (onCallBack) {
          onCallBack();
        }
      } else {
        this.fileListGrid = [];
      }
    });
  }

  setFolderName() {
    if (this.fileListGrid.length > 0) {
      this.folderName = this.fileListGrid[0].fullPath ?? "-";
    }
  }

  clearFileSelection() {
    this.fileNameToUpload = [];
    this.fileToUpload = [];
  }

  selectAllFiles(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.allSelected = isChecked;
    this.fileListGrid.forEach((file) => (file.selected = isChecked));
    this.selectedFiles = isChecked ? [...this.fileListGrid] : [];
  }

  handleFileSelectionChange(file: FileListGrid, event: Event) {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
    this.fileToUpload = [];
    this.fileNameToUpload = [];
    file.selected = (event.target as HTMLInputElement).checked;

    if (this.multiSelect) {
      this.selectedFiles = this.fileListGrid.filter((f) => f.selected);
    } else {
      this.fileListGrid.forEach((f) => (f.selected = false));
      file.selected = true;
      this.selectedFiles = [file];
    }
  }

  handleFileSelection(event: Event) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length) {
      this.clearFileSelection();
      this.fileToUpload = Array.from(inputFile.files);
      this.fileToUpload = this.fileToUpload.map((file) => {
        const newFileName = file.name.replace(/#/g, "");
        const newFile = new File([file], newFileName, { type: file.type });
        return newFile;
      });
      this.fileNameToUpload = this.fileToUpload.map((file) => file.name);
    }
  }

  onChooseFile(fileInput: HTMLInputElement) {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
    this.fileToUpload = [];
    this.fileNameToUpload = [];
    this.selectedFiles = [];
    this.allSelected = false;
    this.fileListGrid.forEach((f) => (f.selected = false));
    fileInput.click();
  }

  async handleFileUploadToStorage() {
    if (!this.fileToUpload.length) {
      this.toaster.error("No file selected", "Error", this.toastObj);
      return;
    }

    this.uploadInProgress = true;
    const filesToUpload = this.fileToUpload.map((file) => file.name);

    this.bankStatementExtractionService.uploadStatementFile(this.loanId!, this.fileToUpload).subscribe((response) => {
      if (response.success) {
        this.fetchFilesByLoanId(() => {
          this.toaster.success("Files Stored Successfully", "Success", this.toastObj);
          const selectedFiles: FileListGrid[] = [];
          for (const gridFiles of this.fileListGrid) {
            if (filesToUpload.includes(gridFiles.name)) {
              gridFiles.selected = true;
              selectedFiles.push(gridFiles);
            }
          }

          this.selectedFiles = selectedFiles;
          this.uploadInProgress = false;
          this.clearFileSelection();
        });
      } else {
        this.toaster.error("Files Failed to Save", "Error", this.toastObj);
        this.clearFileSelection();
      }
    });
  }

  handleOnSelectDocument() {
    if (!this.selectedFiles.length) {
      this.toaster.error("Please select a file before proceeding", "Error", this.toastObj);
      return;
    }

    this.isOpen = false;
    // this.emitOpenChange.emit({ isOpen: this.isOpen });
    this.selectFileEvent.emit(this.selectedFiles);
    this.clearFileSelection();
    this.fileListGrid.forEach((file) => (file.selected = false));
    this.selectedFiles = [];
    this.allSelected = false;
  }

  handleOnCancelPopup() {
    this.isOpen = false;
    this.clearFileSelection();
    this.fileListGrid.forEach((file) => (file.selected = false));
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
    this.selectedFiles = [];
    this.allSelected = false;
    this.emitOpenChange.emit({ isOpen: this.isOpen });
  }

  formatDate(dateString: string): string {
    return moment(dateString).format("DD-MM-YYYY HH:MM");
  }
}
