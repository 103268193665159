import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";

const AuthLoginData_Storage_Key = "AuthLoginData";
const USERTYPE_KEY = "auth-Usertype";
@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  clearStorage() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  setAuthLoginData(data: AccountInfo) {
    localStorage.setItem(AuthLoginData_Storage_Key, JSON.stringify(data));
  }

  getAuthLoginData(): AccountInfo {
    const data = localStorage.getItem(AuthLoginData_Storage_Key);
    return data ? JSON.parse(data) : null;
  }

  getAccessToken() {
    const data = localStorage.getItem(AuthLoginData_Storage_Key);
    return data ? JSON.parse(data).idToken : null;
  }

  getUserRole() {
    const data = localStorage.getItem(AuthLoginData_Storage_Key);
    return data ? (this.getAuthLoginData().localAccountId == "9836f009-6063-45d1-8dae-70f15ecec1ee" ? 1 : 2) : null;
  }

  public getUsertypeToken(): string {
    return sessionStorage.getItem(USERTYPE_KEY) || "";
  }

  getUsername(): string | null {
    const data = this.getAuthLoginData();
    return data ? (data.name ?? null) : null;
  }

  getLocalAccountId(): string | null {
    const authLoginData = this.getAuthLoginData();
    return authLoginData ? authLoginData.localAccountId : null;
  }

  getUserCustomerId(): number {
    const authLoginData = this.getAuthLoginData();
    const id =
      authLoginData.idTokenClaims && authLoginData.idTokenClaims["extension_CurrentCustomer"]
        ? (authLoginData.idTokenClaims["extension_CurrentCustomer"] as number)
        : 0;
    return id;
  }

  updateCustomerId(customerId: number, userRoles: string) {
    const updatedData = { ...this.getAuthLoginData() };
    if (updatedData && updatedData.idTokenClaims) {
      updatedData.idTokenClaims["extension_CurrentCustomer"] = customerId;
      updatedData.idTokenClaims["extension_UserRole"] = userRoles;
      console.log(updatedData);
      this.setAuthLoginData(updatedData);
    }
  }
}
