<p>navbar works!</p>
<div class="toolBar-Image">
  <mat-toolbar class="gap-1">
    <div class="d-flex align-items-center gap-2">
      <a routerLink="/dashboard">
        <img src="/images/MSuite-Logo.png" alt="no image" class="img-thumbnail" />
      </a>
      <span class="separator"></span>
      <h1 class="title">Docs Classification</h1>
      <button mat-stroked-button color="warn">
        <svg xmlns="http://www.w3.org/2000/svg" width="18.833" height="17.216" viewBox="0 0 18.833 17.216">
          <g id="Group_197" data-name="Group 197" transform="translate(0 -20.882)">
            <g id="Group_195" data-name="Group 195" transform="translate(0 20.882)">
              <path
                id="Path_38"
                data-name="Path 38"
                d="M219.193,333.382a.968.968,0,1,0,0,1.936.968.968,0,0,0,0-1.936Z"
                transform="translate(-209.777 -321.284)"
                fill="#ccc" />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M18.374,36.41a3.3,3.3,0,0,0,.008-3.345l-6.063-10.5a3.34,3.34,0,0,0-5.8,0L.45,33.073a3.353,3.353,0,0,0,2.9,5.025h12.11A3.334,3.334,0,0,0,18.374,36.41Zm-1.316-.759a1.829,1.829,0,0,1-1.6.925H3.349a1.8,1.8,0,0,1-1.58-.906,1.832,1.832,0,0,1,0-1.847l6.07-10.507a1.823,1.823,0,0,1,3.167,0l6.066,10.507A1.808,1.808,0,0,1,17.058,35.651Z"
                transform="translate(0 -20.882)"
                fill="#ccc" />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M218.472,157.2a1.053,1.053,0,0,0-.747,1.057c.023.306.043.616.066.921.066,1.165.132,2.307.2,3.473a.709.709,0,0,0,.724.681.724.724,0,0,0,.724-.7c0-.24,0-.461.023-.7.043-.747.089-1.494.132-2.242.023-.484.066-.968.089-1.452a1.2,1.2,0,0,0-.089-.484A.97.97,0,0,0,218.472,157.2Z"
                transform="translate(-209.296 -151.9)"
                fill="#ccc" />
            </g>
          </g>
        </svg>
        Reset
      </button>
    </div>
    <span class="flex-grow"></span>
    <span class="sub-title">Docs Classification / URLA-1003 / 1003-v2 / Page 2</span>
    <button mat-stroked-button color="outline-dark">Undo</button>
    <button mat-stroked-button color="outline-dark">Save Progress</button>
    <button mat-flat-button color="dark">Submit</button>

    <!-- 
            Note:: Add `disabled` attribute if you want to disabled button.     
        -->
  </mat-toolbar>
</div>
