import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { DocsClassificationComponent } from "./docs-classification/docs-classification.component";
import { LoanHeaderDetailsComponent } from "./loan-header-details/loan-header-details.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { SmartPdfViewerComponent } from "./smart-pdf-viewer/smart-pdf-viewer.component";
import { UploadDocumentFromStorageModalComponent } from "./upload-document-from-storage-modal/upload-document-from-storage-modal.component";

@NgModule({
  declarations: [
    PageHeaderComponent,
    UploadDocumentFromStorageModalComponent,
    LoanHeaderDetailsComponent,
    SmartPdfViewerComponent,
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    PdfViewerModule,
    NgxSliderModule,
    DocsClassificationComponent,
    NavbarComponent,
    AdminLayoutComponent,
  ],
  exports: [
    CommonModule,
    PageHeaderComponent,
    UploadDocumentFromStorageModalComponent,
    LoanHeaderDetailsComponent,
    SmartPdfViewerComponent,
    DocsClassificationComponent,
    NavbarComponent,
    AdminLayoutComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
