import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TokenStorageService } from "src/app/service/TokenStorageService/token-storage.service";

export interface UserDetails {
  userId: string;
  userEmail: string;
  customerId: number;
  name: string;
  message: string;
}

export interface UpdateUserDetails {
  userId: string;
  message: string;
  currentCustomerId: number;
  userRole: string;
}

@Injectable({
  providedIn: "root",
})
export class CustomerDetailsService {
  private baseUrl = "https://msuiteuser-dev.azurewebsites.net/api";
  idToken: string;

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  getUserCustomers(): Observable<UserDetails[]> {
    const userId = this.tokenStorageService.getLocalAccountId();
    if (!userId) {
      throw new Error("User ID not found in token storage");
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}/v1/User/Customers/${userId}`;
    return this.http.get<UserDetails[]>(url, { headers });
  }

  getUserContextUpdate(customerId?: number): Observable<UpdateUserDetails> {
    const userId = this.tokenStorageService.getLocalAccountId();
    if (!userId) {
      throw new Error("User ID not found in token storage");
    }
    let url = `${this.baseUrl}/v1/user/userContextUpdate/${userId}`;
    if (customerId) {
      url = `${this.baseUrl}/v1/user/userContextUpdate/${userId}?customerId=${customerId}`;
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<UpdateUserDetails>(url, { headers });
  }
}
