/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDragMove } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  signal,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { SubmitChanges } from "src/app/models/DocClassificationModels/submit-change.model";
import {
  BorrowerMaster,
  CreateBorrowerVersion,
  CreateVersion,
  DocumentDetails,
  DocumentTypeByVersions,
  DocumentTypesMaster,
  PageDetail,
  ResetChanges,
  SaveProcess,
} from "../../../app/models/DocClassificationModels/_index";
import { environment } from "../../../environments/environment";
import { AngularMaterialModule } from "../../material/material.module";
import { saveChanges } from "../../models/DocClassificationModels/save-Changes.model";
import { ApiGridResponseModel, StringDataType, type } from "../../service/DocClassificationService/constants";
import { DocumentService } from "../../service/DocClassificationService/document.service";
import { NotificationService } from "../../service/DocClassificationService/notification.service";

@Component({
  selector: "app-docs-classification",
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, FormsModule, ReactiveFormsModule],
  providers: [DocumentService, { provide: "BASE_URL", useValue: environment.base_url }],
  templateUrl: "./docs-classification.component.html",
  styleUrl: "./docs-classification.component.scss",
})
export class DocsClassificationComponent implements OnInit, AfterViewInit {
  @Input() fileDetails!: MatSidenav;
  private dialog = inject(MatDialog);
  @ViewChild("dialogTemplate", { static: true }) dialogTemplate!: TemplateRef<unknown>;
  @ViewChildren("thumbnailElement") thumbnailElements!: QueryList<ElementRef>;
  @ViewChild("dialogTemplateTracking", { static: true })
  dialogTemplateTracking!: TemplateRef<unknown>;
  dialogRef: MatDialogRef<any> | null = null;
  @ViewChild("dialogTemplateSubmit", { static: true })
  dialogTemplateSubmit!: TemplateRef<unknown>;
  @ViewChild("dialogTemplateReclassify", { static: true })
  dialogTemplateReclassify!: TemplateRef<unknown>;
  documentDetails: PageDetail | null = null; // Variable to store document details
  resetChanges: ResetChanges | null = null;
  saveProgress: SaveProcess;
  notifySuccessBadge = false;
  documentTypeCode!: string;
  borrowerId!: string;
  isReset = false;
  loandId = "56BBA41F-2EB8-415A-B45E-0090BB0239D1";
  VersionId!: string;
  documentTypeId!: string;
  documentID!: string;
  pageId!: string;
  boReclasShow = false;
  boMultiReclasShow = false;
  notifyErrorMsg = false;
  notifySuccessMsg = false;
  lastVersion: any;
  lastBorrower: any;
  imageSize = 985; // Default image size (px)
  imageHeightSize = 1275; // Default image size (px)
  minSize = 100; // Minimum image size
  zoomStep = 100; // Step size for zooming
  imageShow = false;
  showOneDocument = false;
  versionFileName!: string;
  showReclassifyMultiplePages = false;
  pageSelect: number[] = [];
  pageMultiSelect: number[] = [];
  isSaveMultipleData = true;
  showNoImage = false;
  reclassification = false;
  readonly panelOpenState = signal(false);
  images: any[] = [];
  selectedImage: any | null = null;
  selectedEyeImage: any | null = null;
  currentIndex = 0;
  saveCount = 0;
  dragStartIndex = -1;
  isSubmitDisabled = false;
  isResetDisabled = true;
  isUndoDisabled = true;
  saveUndoCount: any | null = null;
  isSaveProgressDisabled = true;
  isActive = false;
  showSplashDocTypeView = true;
  showSplashPageView = true;
  showDocumentView = false;
  showDocumentList = false;
  showDocumentListView = false;
  isOpen = false;
  selectedDocID!: string;
  isButtonVisible = false;
  borrowerShow = false;
  selectedItems = new Set<number>();
  selectedReclassifyItems = new Set<number>();
  lastSelectedIndex: number | null = null;
  notifyMsg = false; // to provide notification
  undoStack: any[][] = []; // To store previous states for undo
  redoStack: any[][] = []; // To store states for redo
  savedMessage!: string;
  mainDocument = "Docs Classification";
  selectedDocDesc!: string; // For document type
  selectedReclsDesc!: string; // For reclassification document type
  selectedMultiReclsDesc!: string; // For reorder reclassification document type
  selectedVersionBoName!: string; // For Borrower
  selectedVersionReclsBoName!: string; // For reclassification borrower Name
  selectedVersionMultiReclsBoName!: string; // For reorder reclassification borrower Name
  selectedReclsVersionName!: string; // change version in reclassify
  selectedMultiReclsVerName!: string; // change version in reorder reclassify
  // Change this to an array of IDocument objects
  documentTypes: DocumentTypesMaster[] = [];
  borrowerTypes: BorrowerMaster[] = [];
  versionList: DocumentTypeByVersions[] = [];
  documentVersionList: DocumentTypeByVersions[] = [];
  thumbnails: DocumentDetails[] = [];
  pageImage: any;
  filteredDocTypes: DocumentTypesMaster[] = []; // Filtered document types for "Document type"
  filteredReclsTypes: DocumentTypesMaster[] = []; // For reclassification
  filteredMultiReclsTypes: DocumentTypesMaster[] = []; // for Reorder reclassification
  filteredBorrowerTypes: BorrowerMaster[] = []; // Filtered document types for "borrower type"
  filterReclsBorrowerTypes: BorrowerMaster[] = []; // For Borrower list in Reclassification page
  filterMultiReclsBorrowerTypes: BorrowerMaster[] = []; // For Borrower list in Reorder Reclassification page
  filteredReclsVersionTypes: DocumentTypeByVersions[] = []; // change version in reclassification
  filteredMultiReclsVersionTypes: DocumentTypeByVersions[] = []; // change version in Reorder reclassification
  data: any;
  selectedDocumentTypeCode!: string;
  isDisabledReclass = true;
  saveChangesArray: saveChanges[] = [];
  saveOrderArray: any[] = [];
  currentOpenVersion: any | null = null;
  isClassifyBoxOpen = false;
  isClassifyMultiBoxOpen = false;
  isReorderOpen = false;
  pageButtonShow = false;
  pageNumber = 0;
  showReorder = false;
  versionReclasShow = false;
  versionMultiReclasShow = false;
  formattedPageSelection = StringDataType.EMPTY;
  countSaveChangesforUndo = 0;
  isNoPage = false;
  pageNo!: string;
  isReclassifyFormMultiple = false;
  isReorderClicked = false;
  isReorderYes = false;
  isReclassifyForm = false;
  isDocumentSelectedPagesRemove = false;
  isToggleOpen = false;
  //for document dropdown select flags
  isDocumentclicked = false;
  isDocumentMultiClicked = false;
  isReorderMultipageOpen = false;
  isReclassifyPageReorder = false;
  isMultiDocumentOpen = false;
  saveChangesShow = false;
  isNewVersion = false;
  multiReclassification = false;
  mainBorrowerId!: string;
  reorderSuccess = false;
  reclassificationSuccess = false;
  isPrevDisabled = false; // Set the default state
  isNextDisabled = false; // Set the default state
  isReorderChange = true;
  saveReorder = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public notificationService: NotificationService,
    private documentService: DocumentService
  ) {
    this.resetChanges = new ResetChanges();
    this.saveProgress = new SaveProcess();
  }

  clickOnDocumentDropdown(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.isSubmitDisabled = false;
    if ((this.isToggleOpen && this.selectedReclsVersionName) || this.selectedVersionReclsBoName) {
      this.isDocumentclicked = true;
      //this.isToggleOpen = false;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else if (
      (this.isMultiDocumentOpen && this.selectedMultiReclsDesc && this.selectedMultiReclsVerName) ||
      this.selectedVersionMultiReclsBoName
    ) {
      this.isDocumentMultiClicked = true;
      //this.isMultiDocumentOpen = false;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else if (this.isReorderMultipageOpen && this.pageSelect.length > 0) {
      this.isDocumentSelectedPagesRemove = true;
      this.isReorderMultipageOpen = true;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    }
  }

  ngAfterViewInit(): void {
    // Ensure the selected thumbnail is scrolled into view when the view initializes
    this.scrollToCurrentIndex();
  }

  // Scroll to the element corresponding to the currentIndex
  scrollToCurrentIndex(): void {
    const selectedThumbnail = this.thumbnailElements.get(this.currentIndex);
    if (selectedThumbnail) {
      selectedThumbnail.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }

  ngOnInit(): void {
    if (typeof window !== "undefined" && window.sessionStorage) {
      // Safe to use sessionStorage
      const count = sessionStorage.getItem("saveCount");
      this.countSaveChangesforUndo = Number(count);
      //console.log(storedData);
    }
    this.getDocumentTypeListData(this.isActive);
    //this.getVersionsList();
  }

  // Method to get the formatted text in BreadScrumb
  getFormattedVersion(): string {
    if (this.versionFileName) {
      return this.selectedVersionBoName
        ? `/ ${this.selectedVersionBoName}-${this.versionFileName}`
        : `/ ${this.versionFileName}`;
    }
    return "";
  }

  getFormattedVersionWRemoveSlash(): string {
    if (this.versionFileName) {
      return this.selectedVersionBoName
        ? `${this.selectedVersionBoName}-${this.versionFileName}`
        : this.versionFileName;
    }
    return "";
  }

  getPageNoformatter(): string {
    if (this.isNoPage) return "";
    return this.pageNo ? `/ Page ${parseInt(this.pageNo)}` : "";
  }

  isExpanded(version: any, isFirst: any): boolean {
    // Open the first panel by default and keep track of the open version
    return this.currentOpenVersion ? this.currentOpenVersion === version : isFirst;
  }

  toggleClass(enterAnimationDuration: string, exitAnimationDuration: string) {
    let dialogReclsRef: any;
    if (!this.showReclassifyMultiplePages) {
      // Open the dialog
      dialogReclsRef = this.dialog.open(this.dialogTemplateReclassify, {
        autoFocus: false,
        width: "550px",
        enterAnimationDuration,
        exitAnimationDuration,
      });
    }
    this.showReorder = false;

    // Add logic to execute before the dialog starts closing
    // dialogReclsRef.beforeClosed().subscribe(() => {
    //   this.isOpen = true;
    //   this.openDialogTracking(enterAnimationDuration, exitAnimationDuration);
    // });
    // Add logic after the dialog is completely closed
    dialogReclsRef.afterClosed().subscribe(() => {
      console.log("After closing logic triggered");
      this.showReorder = true;
      this.clearReclassifyData();
    });
    if (
      (this.selectedMultiReclsDesc !== StringDataType.EMPTY &&
        this.selectedMultiReclsVerName !== StringDataType.EMPTY) ||
      this.selectedVersionMultiReclsBoName !== StringDataType.EMPTY
    ) {
      this.isReclassifyForm = true;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
      // this.selectedMultiReclsDesc = StringDataType.EMPTY;
      // this.selectedMultiReclsVerName = StringDataType.EMPTY;
      // this.isOpen = !this.isOpen;
      // this.getDocumentTypeListData(this.isActive);
    }

    // if reclassify page click only when model shows
    else if (this.isReorderMultipageOpen && this.pageSelect.length > 0) {
      this.isReclassifyPageReorder = true;
      // add code in else block
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else {
      this.isToggleOpen = true;
      this.isOpen = this.isActive ? true : false;
      //this.isButtonVisible = !this.isButtonVisible;
      console.log("this.isOpen", this.isOpen);
      this.getDocumentTypeListData(this.isActive);
    }
  }

  changeTrackDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    this.dialogRef = this.dialog.open(this.dialogTemplateTracking, {
      width: "550px",
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  reclassifySubmit() {
    this.reclassificationSuccess = true;

    this.borrowerId ? this.borrowerId : null;
    // if reclassify page click only when model shows
    if (this.isActive && this.selectedReclsDesc && this.selectedReclsVersionName && this.borrowerId != null) {
      // need to create onject and pass to this.saveChanges();
      // Create and populate `saveChangeObj`
      const saveChangeObj = new saveChanges();
      saveChangeObj.pageId = this.pageId;
      saveChangeObj.loanId = this.loandId;
      saveChangeObj.currentDocumentId = this.documentID;
      saveChangeObj.currentPageNumber = this.pageNumber;
      saveChangeObj.updatedDocumentId = null;
      saveChangeObj.updatedPageNumber = 0;
      saveChangeObj.userId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
      saveChangeObj.documentType = this.selectedDocumentTypeCode;
      saveChangeObj.version = this.VersionId;
      saveChangeObj.borrowerId = this.borrowerId;
      saveChangeObj.isAllowed = 0;
      this.isOpen = !this.isOpen;
      // Call saveChanges and pass `saveChangeObj`
      this.reclassification = true;
      this.saveChanges([saveChangeObj]);
      this.clearModel();
      this.CloseReclassifyDialog();
      this.showReorder = true;
      //this.getVersionsList();
    }
  }

  selectedPanelOpen(version: any) {
    this.formattedPageSelection = StringDataType.EMPTY;
    this.pageButtonShow = true;
    this.versionFileName = StringDataType.EMPTY;
    this.selectedItems.clear();
    if (version) {
      this.currentOpenVersion = version;
      this.panelOpenState.set(true);
      // Additional logic for when a panel is opened
      //console.log("Panel opened");
      // called thumbnail here
      this.versionFileName = version.file_Name;
      this.documentID = version.id;
      this.currentIndex = 0;

      this.currentIndex === 0 ? (this.isPrevDisabled = true) : (this.isPrevDisabled = false);
      this.selectThumbnails(this.loandId, this.documentID, this.mainBorrowerId);
    }
  }

  selectedPanelClosed() {
    //console.log("this.documentVersionList?", this.documentVersionList);
    //console.log("this.currentOpenVersion", this.currentOpenVersion);
    this.panelOpenState.set(false);
  }

  getDocumentTypeListData(isActive: boolean) {
    // Initially show all document types
    this.documentTypes = [];
    this.documentService.getDocumentList(isActive ? type.available : type.all, this.loandId)?.subscribe({
      next: (res: ApiGridResponseModel<any>) => {
        if (res?.data?.documentTypes) {
          this.documentTypes = res?.data?.documentTypes;
        } else {
          console.log("No data present");
        }
      },
      error: (err: any) => {
        console.error("Error", err);
      },
      complete: () => {
        // Handle any completion logic if needed
        // Bind to Document type
        if (!isActive) {
          // if reclassify pages is closed then documentlist bind to main document type
          this.filteredDocTypes = this.documentTypes;
        } else {
          this.filteredMultiReclsTypes = this.documentTypes;
        }
        if (this.isOpen) {
          // if reclassify page open then list bind to reclassify to
          this.filteredReclsTypes = this.documentTypes;
        } else {
          this.clearModel();
        }
      },
    });
  }

  // clear all data
  clearModel() {
    this.formattedPageSelection = StringDataType.EMPTY;
    this.saveChangesArray = [];
    this.selectedReclassifyItems.clear();
    this.selectedItems.clear();
    this.isDisabledReclass = true;
    this.borrowerId = StringDataType.EMPTY;
    this.isSaveMultipleData = true;
    this.selectedDocumentTypeCode = StringDataType.EMPTY;
    this.VersionId = StringDataType.EMPTY;
    this.selectedItems.clear();
    this.versionMultiReclasShow = false;
    this.isReorderChange = true;
    this.boMultiReclasShow = false;
    this.selectedReclsDesc = StringDataType.EMPTY;
    this.selectedVersionReclsBoName = StringDataType.EMPTY;
    this.selectedReclsVersionName = StringDataType.EMPTY;
    this.selectedMultiReclsDesc = StringDataType.EMPTY;
    this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
    this.selectedMultiReclsVerName = StringDataType.EMPTY;
    this.selectedMultiReclsDesc = StringDataType.EMPTY;
    this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
    this.selectedMultiReclsVerName = StringDataType.EMPTY;
    this.pageSelect = [];
    this.pageMultiSelect = [];
  }

  get filteredOptions() {
    return this.filteredMultiReclsVersionTypes?.length ? this.filteredMultiReclsVersionTypes : [];
  }

  getVersionsList(documentTypeId: any, loandId: any, borrowerId?: any) {
    this.versionList = [];
    this.documentService.getVersionsList(documentTypeId, loandId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.rdDocumentTypeVersions) {
          this.versionList = res?.data?.rdDocumentTypeVersions;
          this.filteredReclsVersionTypes = this.versionList;
          this.filteredMultiReclsVersionTypes = this.versionList;
          const filteredV1 = this.filteredReclsVersionTypes.filter((version: any) => {
            const fileVersionPrefix = this.versionFileName.split("-")[0]; // Extract prefix from versionFileName
            return version.description === fileVersionPrefix; // Match the exact version
          });
          this.filteredReclsVersionTypes = this.versionList.filter((version: any) => {
            return !filteredV1.some(
              (v1: any) => v1.description === version.description && documentTypeId === this.documentTypeId
            );
          });
          this.filteredMultiReclsVersionTypes = this.versionList.filter((version: any) => {
            return !filteredV1.some(
              (v1: any) => v1.description === version.description && documentTypeId === this.documentTypeId
            );
          });

          //console.log("res.data for this.filteredReclsVersionTypes", this.filteredReclsVersionTypes);
          // Storing the last value of versionList
          if (this.versionList.length > 0) {
            this.lastVersion = this.versionList[this.versionList.length - 1].description;
            // Extract the number from the version string
            const versionNumber = parseInt(this.lastVersion.replace(/\D/g, StringDataType.EMPTY), 10);
            // Increment the version number
            const newVersionNumber = versionNumber + 1;
            // Form the new version string
            this.lastVersion = `v${newVersionNumber}`;
            //console.log("New last version:", this.lastVersion);
          }
        }
      },
      error: (err: any) => {
        console.log("Error", err);
      },
    });
  }

  getSelectDocTypeVersionList(loandId: string, documentTypeId: string, borrowerId?: string) {
    this.documentService.getDocumentVersionList(loandId, documentTypeId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.documentTypeByVersions) {
          //console.log(res);
          //console.log("response of documentTypeByVersions", res?.data?.documentTypeByVersions);
          this.documentVersionList = res?.data?.documentTypeByVersions;
          // Check if any panel is open; if none, open the first panel
          if (!this.currentOpenVersion && this.documentVersionList.length) {
            this.selectedPanelOpen(this.documentVersionList[0]);
            this.showReorder = true;
          }
          if (this.documentVersionList.length < 0) {
            this.showSplashDocTypeView = false;
            this.showSplashPageView = false;
          } else {
            this.selectedPanelOpen(this.documentVersionList[0]);
            this.showReorder = true;
          }
          //console.log("this.documentVersionList", this.documentVersionList);
        } else {
          console.log("Empty data");
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  getSelectDocTypeVersionListofReclassify(loandId: string, documentTypeId: string, borrowerId?: string) {
    this.documentService.getDocumentVersionList(loandId, documentTypeId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.documentTypeByVersions) {
          //console.log(res);
          //console.log("response of documentTypeByVersions", res?.data?.documentTypeByVersions);
          this.documentVersionList = res?.data?.documentTypeByVersions;
          // Check if any panel is open; if none, open the first panel

          //console.log("this.documentVersionList", this.documentVersionList);
        } else {
          console.log("Empty data");
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  getBorrowerList(loandId: string, documentTypeId: string) {
    this.borrowerTypes = [];
    this.documentService.getBorrowerList(loandId, documentTypeId)?.subscribe({
      next: (res: any) => {
        if (res.data.documentTypeVersions) {
          this.isActive = false;
          this.showDocumentList = true;
          res.data.documentTypeVersions.forEach((borrowerType: any) => {
            this.borrowerTypes.push(borrowerType);
            //console.log("select borrower List", this.borrowerTypes);
          });
          this.filteredBorrowerTypes = this.borrowerTypes;
          //console.log("select filteredBorrowerTypes", this.filteredBorrowerTypes);
          if (this.isOpen) {
            // if reclassify page open then list bind to select borrower
            this.filterReclsBorrowerTypes = this.borrowerTypes;
          }
          this.filterMultiReclsBorrowerTypes = this.borrowerTypes;
          // Storing the last value of borrowerTypes
          if (this.borrowerTypes.length > 0) {
            this.lastBorrower = this.borrowerTypes[this.borrowerTypes.length - 1].versioning_Borrower_Name;
            // Extract the number from borrower and increment by 1
            const number = parseInt(this.lastBorrower.replace(/^\D+/g, StringDataType.EMPTY), 10) + 1;

            // Decide the prefix based on the initial borrower type ("B" or "Borrower")
            if (this.lastBorrower.startsWith("Borrower")) {
              this.lastBorrower = "Borrower" + number;
            } else if (this.lastBorrower.startsWith("B")) {
              this.lastBorrower = "B" + number;
            }
            console.log("this.lastBorrower", this.lastBorrower);
          }
        }
      },
      error: (err: unknown) => {
        console.log("Error", err);
      },
    });
  }

  zoomIn() {
    this.imageSize += this.zoomStep;
  }

  zoomOut() {
    if (this.imageSize > this.minSize) {
      this.imageSize -= this.zoomStep;
    }
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(this.dialogTemplate, {
      width: "550px",
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  Reclassify(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(this.dialogTemplateReclassify, {
      width: "550px",
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openDialogForSubmitChange(enterAnimationDuration: string, exitAnimationDuration: string): void {
    if (!this.reclassificationSuccess && !this.reorderSuccess) {
      this.notificationService.showNotification("You’ve not made any changes. Do you want to Submit?");
    } else if (this.reclassificationSuccess && !this.reorderSuccess) {
      this.notificationService.showNotification(
        "Reclassified pages have not been reordered. Please review and reorder the pages, if necessary, before submitting for further processing."
      );
    }
    this.dialog.open(this.dialogTemplateSubmit, {
      width: "550px",
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openDialogTracking(enterAnimationDuration: string, exitAnimationDuration: string): void {
    if (this.selectedReclsDesc && this.selectedReclsVersionName) {
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
      this.dialogRef?.afterClosed().subscribe((result) => {
        if (result === "confirm") {
          this.resetReclassifyForm();
        } else {
          //this.restoreFormData();
          this.isOpen = true;
        }
        //this.dialogRef = null;
      });
    } else {
      this.CloseReclassifyDialog();
    }
  }

  pageDetails(loandId: string, documentID: string, pageId: string) {
    this.documentService.getPageDetails(loandId, documentID, pageId)?.subscribe({
      next: (res: any) => {
        this.documentDetails = res.data.documentDetails[0];
        // console.log(res.data.documentDetails[0]);
      },
      error: (err: unknown) => {
        console.log(err);
      },
    });
  }

  getImage(loandId: string, documentID: string, pageId: string) {
    this.documentService.getImageDetail(loandId, documentID, pageId)?.subscribe({
      next: (res: any) => {
        if (res?.data) {
          this.pageImage = `data:image/png;base64,${res.data.pageImage[0]?.image_Path}`;
          //console.log("image resolution", res.data.pageImage[0]?.image_Path);
          //console.log("this.pageImage", this.pageImage);
        }
        // console.log(res.data.documentDetails[0]);
      },
      error: (err: unknown) => {
        console.log(err);
      },
    });
  }

  toggleReorderSelection(index: number, image?: any, event?: MouseEvent | KeyboardEvent) {
    // Check if Ctrl or Meta key is pressed
    const isCtrlPressed = event ? event.ctrlKey || event.metaKey : false;
    const pageIndex = index + 1; // Adjust index to match page numbering

    if (image) {
      this.getImage(this.loandId, this.documentID, image.pageId);
    }

    if (isCtrlPressed) {
      // Handle Ctrl/Meta key pressed logic for multi-selection
      const pageIndexPosition = this.pageSelect.indexOf(pageIndex);
      if (pageIndexPosition === -1) {
        this.pageSelect.push(pageIndex); // Add to selection if not already selected
        this.selectedItems.add(pageIndex); // Track in selected items set
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, image, pageIndex); // Save changes for selection
      } else {
        this.pageSelect.splice(pageIndexPosition, 1); // Remove from selection if already selected
        this.selectedItems.delete(pageIndex); // Remove from selected items set
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, false, image, pageIndex); // Save changes for deselection
      }
    } else {
      // Clear existing selection and select the current item
      this.selectedItems.clear();
      this.selectedItems.add(pageIndex);
      this.pageSelect = [pageIndex];
      this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, image, pageIndex); // Save changes for the single selection
    }

    // Handle Shift key for range selection
    if (this.lastSelectedIndex !== null && event?.shiftKey) {
      const start = Math.min(index, this.lastSelectedIndex);
      const end = Math.max(index, this.lastSelectedIndex);
      for (let i = start; i <= end; i++) {
        if (!this.pageSelect.includes(i + 1)) {
          this.pageSelect.push(i + 1); // Add to selection range
          this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, image, i + 1); // Save changes for range selection
        }
        this.selectedItems.add(i + 1);
      }
    }

    this.lastSelectedIndex = pageIndex;

    // Format the selected pages
    this.formattedPageSelection = this.formatSelectedPages(this.pageSelect);
  }

  toggleMultipleSelection(index: number, image?: any, event?: MouseEvent | KeyboardEvent) {
    this.multiReclassification = true;
    this.reclassification = false;
    //console.log("image", image);
    const pageIndex = index + 1;
    // Check if Ctrl key is pressed
    const isCtrlPressed = event ? event.ctrlKey : false;
    if (image) {
      this.getImage(this.loandId, this.documentID, image.pageId);
      //this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, image, pageIndex);
    }
    // with controlkey pressed
    if (isCtrlPressed) {
      const pageIndexPosition = this.pageMultiSelect.indexOf(pageIndex);
      if (pageIndexPosition === -1) {
        this.pageMultiSelect.push(pageIndex);
        this.selectedReclassifyItems.add(pageIndex);
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, image, pageIndex); // Selected
      } else {
        this.pageMultiSelect.splice(pageIndexPosition, 1);
        this.selectedReclassifyItems.delete(pageIndex);
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, false, image, pageIndex); // Deselected
      }
    } else {
      const pageIndexPosition = this.pageMultiSelect.indexOf(pageIndex);
      if (pageIndexPosition === -1) {
        this.pageMultiSelect.push(pageIndex);
        this.selectedReclassifyItems.add(pageIndex);
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, image, pageIndex); // Selected
      } else {
        this.pageMultiSelect.splice(pageIndexPosition, 1);
        this.selectedReclassifyItems.delete(pageIndex);
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, false, image, pageIndex); // Deselected
      }
    }
    // Format the selected pages
    this.formattedPageSelection = this.formatSelectedPages(this.pageMultiSelect);
  }

  // here create save change object
  SaveChangeObjectCreate(
    reclassification: boolean,
    multiReclassification: boolean,
    isSelected: boolean,
    image?: any,
    pageIndex?: number
  ) {
    if (!isSelected) {
      // Remove the object from saveChangesArray if deselected
      this.saveChangesArray = this.saveChangesArray.filter((obj) => obj.pageIndex !== pageIndex);
      return; // Exit the function since no further action is needed for deselection
    }
    const saveChangeObj = new saveChanges();
    if ((reclassification || multiReclassification) && isSelected) {
      // create object for reclassification
      saveChangeObj.pageIndex = pageIndex ?? 0;
      saveChangeObj.pageId = image?.pageId;
      saveChangeObj.loanId = this.loandId;
      saveChangeObj.currentDocumentId = this.documentID;
      saveChangeObj.currentPageNumber = image?.pageNumber;
      saveChangeObj.updatedDocumentId = null;
      saveChangeObj.updatedPageNumber = 0;
      saveChangeObj.userId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
      saveChangeObj.documentType = this.selectedDocumentTypeCode;
      saveChangeObj.version = this.VersionId;
      saveChangeObj.borrowerId = this.borrowerId ?? null;
      saveChangeObj.isAllowed = 0;
      //console.log("saveChangeObj", saveChangeObj);
      // Add the created saveChangeObj to saveChangesArray
      this.saveChangesArray.push(saveChangeObj);
      // Sort saveChangesArray by pageIndex or currentPageNumber
      this.saveChangesArray.sort((a, b) => {
        const indexA = a.pageIndex ?? ""; // Use 0 if undefined
        const indexB = b.pageIndex ?? ""; // Use 0 if undefined
        return indexA - indexB;
      });
      //console.log("this.saveChangesArray", this.saveChangesArray);
    } else {
      if (isSelected) {
        // create object for reOrder
        saveChangeObj.pageIndex = pageIndex ?? 0;
        saveChangeObj.pageId = image.currentPageId ? image.currentPageId : image.pageNewId;
        saveChangeObj.loanId = this.loandId;
        saveChangeObj.currentDocumentId = this.documentID;
        saveChangeObj.currentPageNumber = image?.currentPageNo;
        saveChangeObj.updatedDocumentId = this.documentID;
        saveChangeObj.updatedPageNumber = image?.updatedPageNo;
        saveChangeObj.userId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
        saveChangeObj.documentType = null;
        saveChangeObj.version = null;
        saveChangeObj.borrowerId = this.borrowerId ?? null;
        saveChangeObj.isAllowed = image.isAllowed;
        //console.log("saveChangeObj", saveChangeObj);
        // Add the created saveChangeObj to saveChangesArray
        this.saveChangesArray.push(saveChangeObj);
        console.log("this.saveChangesArray", this.saveChangesArray);
      }
    }

    // Add the created saveChangeObj to saveChangesArray
    console.log("this.saveChangesArray", this.saveChangesArray);
  }

  drop(event: any) {
    this.pageSelect = [];
    this.reclassification = false;
    this.multiReclassification = false;
    this.isReorderChange = false;
    if (!event.previousContainer || !event.container) {
      return;
    }
    const selectedIndices = Array.from(this.selectedItems).sort((a, b) => a - b);

    if (selectedIndices.length > 0) {
      // Multiple items selected, handle each separately
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      selectedIndices.forEach((index, idx) => {
        const data = {
          currentPageNo: this.thumbnails[index - 1].pageNumber,
          pageNewId: this.thumbnails[index - 1].pageId,
          updatedPageNo: this.thumbnails[index - 1].pageNumber,
          isAllowed: 2,
        };
        console.log("Multi drag and drop data", data);
        this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, data);
      });
      this.handleMultiDrop(selectedIndices, event.currentIndex);
      this.highlightDroppedItems(selectedIndices);
      this.clearSelection();
    }
    this.reorderItems(); // Reorder page numbers after drop
  }

  highlightDroppedItems(indices: number[]) {
    indices.forEach((index) => {
      const element = document.querySelectorAll(".image-container")[index - 1];
      if (element) {
        element.classList.add("highlighted");
        setTimeout(() => element.classList.remove("highlighted"), 2000);
      }
    });
  }

  handleMultiDrop(selectedIndices: number[], currentIndex: number) {
    // Map selected items
    const selectedItems = selectedIndices.map((index) => this.thumbnails[index - 1]);
    // Sort indices in descending order for safe removal
    selectedIndices
      .sort((a, b) => b - a)
      .forEach((index) => {
        this.thumbnails.splice(index - 1, 1); // Remove selected items
      });
    // Adjust currentIndex for cases where items are dragged from before the drop position
    let adjustedIndex = currentIndex;
    // If any selected item is from before the drop position, adjust index
    if (selectedIndices.some((index) => index - 1 < currentIndex - 1)) {
      adjustedIndex = Math.max(currentIndex - selectedItems.length - 1, 0);
      this.thumbnails.splice(adjustedIndex + 2, 0, ...selectedItems);
    } else {
      // else Insert items at the index
      this.thumbnails.splice(currentIndex, 0, ...selectedItems);
    }
    console.log("Updated thumbnails:", this.thumbnails);
  }

  reorderItems() {
    this.thumbnails.forEach((item, index) => {
      item.pageNumber = index + 1;
    });
  }

  undo() {
    this.onOptionSelect();
    this.undoChanges();
    if (this.countSaveChangesforUndo > 0) {
      this.reclassificationSuccess = false;
      this.reorderSuccess = false;
      this.countSaveChangesforUndo--;
      sessionStorage.setItem("saveCount", String(this.countSaveChangesforUndo));
    }
  }

  areStatesEqual(currentState: any[], previousState: any[]): boolean {
    if (currentState.length !== previousState.length) {
      return false;
    }
    for (let i = 0; i < currentState.length; i++) {
      if (JSON.stringify(currentState[i]) !== JSON.stringify(previousState[i])) {
        return false;
      }
    }
    return true;
  }

  pushInitialStateToUndoStack() {
    if (this.undoStack.length === 0) {
      const currentState = [...this.thumbnails.map((item) => ({ ...item }))];
      this.undoStack.push(currentState); // Save the initial state
    }
  }

  saveChangesforUndo() {
    this.pushToUndoStack(); // Push the current state to undo stack
    this.countSaveChangesforUndo++;
    sessionStorage.setItem("saveCount", String(this.countSaveChangesforUndo));
    // this.redoStack = [];     // Clear redo stack

    // this.redoStack = [];     // Clear redo stack
    this.savedMessage = "Changes saved!";
    setTimeout(() => (this.savedMessage = StringDataType.EMPTY), 1000000);
  }

  // Push the current state to the undo stack, avoiding duplicates
  pushToUndoStack() {
    const currentState = [...this.thumbnails.map((item) => ({ ...item }))];
    // If the undo stack is empty, push the current state
    if (this.undoStack.length === 0) {
      this.undoStack.push(currentState);
      // this.notificationService.showSuccess("Changes saved successfully");
      this.disableControls();
      return;
    }
    // Check if the current state is identical to the last pushed state
    const lastState = this.undoStack[this.undoStack.length - 1];
    if (this.areStatesEqual(currentState, lastState)) {
      // this.notificationService.showSuccess("Changes saved successfully");
      this.disableControls();
      return; // Don't push duplicate states
    }
    // Maintain a maximum of 5 states in the undo stack
    if (this.undoStack.length >= 6) {
      this.undoStack.shift(); // Remove the oldest state if we exceed 5
    }
    this.undoStack.push(currentState); // Push a deep copy of the current state
    // this.saveChanges();
  }

  clearSelection() {
    this.selectedItems.clear();
    this.lastSelectedIndex = null;
  }

  initialUndoFunctionality() {
    this.saveUndoCount = sessionStorage.getItem("saveCount");
    if (this.saveUndoCount) {
      const count = Number(this.saveUndoCount);
      this.saveCount = count;
    }
  }

  // image shows in main grid
  showImage(index: number, image?: DocumentDetails) {
    const lastElement = this.thumbnails.length - 1;
    if (index === 0) {
      this.isPrevDisabled = true;
      this.isNextDisabled = false;
    }
    if (index > 0) {
      this.isPrevDisabled = false;
      this.isNextDisabled = false;
    }
    if (index == lastElement) {
      this.isPrevDisabled = false;
      this.isNextDisabled = true;
    }
    if (lastElement == 0) {
      this.isPrevDisabled = true;
      this.isNextDisabled = true;
    }
    this.pageNo = (index + 1).toString();
    // If only the index is provided, retrieve the image from thumbnails
    if (image === undefined) {
      image = this.thumbnails[index];
    }
    if (image) {
      this.imageShow = true;
      this.pageId = image.pageId;
      this.pageNumber = image.pageNumber;
      // call particular image and its page details using below functions
      this.getImage(this.loandId, this.documentID, image?.pageId);
      this.pageDetails(this.loandId, this.documentID, image?.pageId);
    }
    this.currentIndex = index;
    // Scroll the current indec in left hand side panel
    this.scrollToCurrentIndex();
    this.showDocumentView = true;
    this.showDocumentListView = false;
    this.showReclassifyMultiplePages = false;
    this.showSplashDocTypeView = false;
    this.showSplashPageView = false;
    this.isActive = true;
  }

  // image shows not in main grid
  showEyeImage(image: any, index: number) {
    this.pageNo = (index + 1).toString();
    this.showOneDocument = true;
    this.currentIndex = index;
    this.getImage(this.loandId, this.documentID, image.pageId);
  }

  // select All Pages For Reclassifications
  selectAllPages() {
    this.saveChangesArray = [];
    this.multiReclassification = true;
    this.saveChangesShow = true;
    this.reclassification = false;
    this.selectedReclassifyItems.clear(); // Clear previous selections
    this.pageMultiSelect = []; // Reset pageMultiSelect to avoid duplicates
    // Iterate through thumbnails to select all pages
    this.thumbnails.forEach((ele: any, index) => {
      console.log("this.thumbnails");
      this.SaveChangeObjectCreate(this.reclassification, this.multiReclassification, true, ele);
      this.pageMultiSelect.push(index + 1); // Add page index to pageMultiSelect
      this.selectedReclassifyItems.add(index + 1); // Add to selectedReclassifyItems
    });
    // Format the selected pages
    this.formattedPageSelection = this.formatSelectedPages(this.pageMultiSelect);
  }

  // Format array in page selected UI
  formatSelectedPages(selectedPages: number[]): string {
    if (!selectedPages || selectedPages.length === 0) return "";

    // Sort the pages first
    selectedPages.sort((a, b) => a - b);

    const formattedRanges: string[] = [];
    let currentRangeStart = selectedPages[0];
    let currentRangeEnd = selectedPages[0];

    for (let i = 1; i < selectedPages.length; i++) {
      const page = selectedPages[i];
      if (page === currentRangeEnd + 1) {
        // Pages are consecutive, extend the range
        currentRangeEnd = page;
      } else {
        // Add the current range or single page
        if (currentRangeStart === currentRangeEnd) {
          formattedRanges.push(currentRangeStart.toString());
        } else {
          formattedRanges.push(`${currentRangeStart}-${currentRangeEnd}`);
        }
        // Start a new range
        currentRangeStart = page;
        currentRangeEnd = page;
      }
    }
    // Add the last range or single page
    if (currentRangeStart === currentRangeEnd) {
      formattedRanges.push(currentRangeStart.toString());
    } else {
      formattedRanges.push(`${currentRangeStart}-${currentRangeEnd}`);
    }
    return formattedRanges.join(", ");
  }

  // exit multiple page
  exitReclasMultiplePage() {
    this.isReclassifyFormMultiple = false;
    this.isActive = true;
    this.versionMultiReclasShow = false;
    this.selectedMultiReclsDesc = StringDataType.EMPTY;
    this.boMultiReclasShow = false;
    this.selectedReclassifyItems.clear(); // Clear previous selections
    this.formattedPageSelection = StringDataType.EMPTY;
    this.saveChangesArray = [];
    this.selectedItems.clear();
    this.selectedPanelOpen(this.documentVersionList[0]);
    this.showReorder = true;
    // this.showImage((this.currentIndex = 0));
  }

  // show previous img
  prevImage() {
    //console.log("Before prevImage, documentVersionList:", this.documentVersionList);
    if (this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      this.pageNo = (this.currentIndex + 1).toString();
      this.showImage(this.currentIndex);
    }
    //console.log("After prevImage, documentVersionList:", this.documentVersionList);
  }

  // show next img
  nextImage() {
    //console.log("Before nextImage, documentVersionList:", this.documentVersionList);
    if (this.currentIndex < this.thumbnails.length - 1) {
      this.currentIndex = this.currentIndex + 1;
      this.pageNo = (this.currentIndex + 1).toString();
      this.showImage(this.currentIndex);
    }
    //console.log("After nextImage, documentVersionList:", this.documentVersionList);
  }

  // Closes the dialog with a specific action
  closeDialog(action: string) {
    if (this.dialogRef) {
      this.dialogRef.close(action);
      if (action == "confirm") {
        this.isActive = false;
        if (this.isReclassifyFormMultiple) {
          this.configureReclassifyState();
        }
        this.CloseReclassifyDialog();
        this.resetReclassifyForm();
      }
    }
  }

  // Refactored common logic into a private method
  private configureReclassifyState(): void {
    this.isActive = true;
    this.showReorder = false;
    this.isOpen = !this.isOpen;
    this.isMultiDocumentOpen = true;
    this.showDocumentView = false;
    this.showDocumentList = true;
    this.showDocumentListView = false;
    this.reclassification = false;
    this.showReclassifyMultiplePages = true;
    this.isButtonVisible = false;
  }

  // Method to filter the document list based on input
  filterDocuments(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.toLowerCase();
    //console.log("filterValue", filterValue);
    this.filteredDocTypes = this.documentTypes.filter((documentType) =>
      documentType.description.toLowerCase().includes(filterValue)
    );
  }

  // Method to filter the document list based on input
  filterBorrowers(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.toLowerCase();

    this.filteredBorrowerTypes = this.borrowerTypes.filter((borrowerTypes) =>
      borrowerTypes.versioning_Borrower_Name.toLowerCase().includes(filterValue)
    );
  }

  // Filter the document list for "Reclassify To"
  filterReclassifyTypes(event: Event): void {
    //console.log("this.documentTypes", this.documentTypes);
    const inputValue = event.target as HTMLInputElement;
    const filterValue = inputValue.value.toLowerCase();
    //console.log("filterValue", filterValue);
    this.filteredReclsTypes = this.documentTypes.filter((documentType) =>
      documentType.description.toLowerCase().includes(filterValue)
    );
  }

  // Filter the document list for Reorder "Reclassify To"
  filterReorderReclassifyTypes(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredMultiReclsTypes = this.documentTypes.filter((documentType) =>
      documentType.description.toLowerCase().includes(inputValue)
    );
  }

  // Filter the borrowerList for "Reclassification page"
  filterReclassifyBorrowers(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.toLowerCase();

    this.filterReclsBorrowerTypes = this.borrowerTypes.filter((borrowerTypes) =>
      borrowerTypes.versioning_Borrower_Name.toLowerCase().includes(filterValue)
    );
  }

  // Filter the borrowerList for " Reorder Reclassification page"
  filterReorderReclassifyBorrowers(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.toLowerCase();

    this.filterMultiReclsBorrowerTypes = this.borrowerTypes.filter((borrowerTypes) =>
      borrowerTypes.versioning_Borrower_Name.toLowerCase().includes(filterValue)
    );
  }

  filterReclassifyVersionsChanges(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredReclsVersionTypes = this.versionList.filter((documentType) =>
      documentType.description.toLowerCase().includes(inputValue)
    );
  }

  filterMultiReclassifyVersionChanges(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredMultiReclsVersionTypes = this.versionList.filter((documentType) =>
      documentType.description.toLowerCase().includes(inputValue)
    );
  }

  submitChangeForFurterProcess(): void {
    this.notificationService.showSuccess("Submitted Successfully.");
  }

  onOptionSelect() {
    this.isResetDisabled = false;
    this.isSaveProgressDisabled = false;
    //this.isUndoDisabled = false;
    //this.isSaveProgressDisabled = false;
  }

  disableControls() {
    this.isResetDisabled = false;
    this.isSaveProgressDisabled = false;
    this.isUndoDisabled = false;
    this.isSaveProgressDisabled = false;
  }

  // For getting thumbnails list here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectThumbnails(loandId: string, documentId: string, borrowerId?: string, showNoImage?: boolean) {
    this.thumbnails = [];
    this.isNoPage = false;
    this.documentService.getPageThumbnailList(loandId, documentId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.documentDetails) {
          // Map the response to convert `thumbnailPath` directly for each document
          this.thumbnails = res.data.documentDetails.map((doc: any) => {
            return {
              ...doc,
              thumbnailPath: `data:image/png;base64,${doc.thumbnailPath}`, // Prepend base64 header
            };
          });
          // called showImage here
          if (!this.saveChangesShow) {
            console.log("No image", this.saveChangesShow);
            this.currentIndex = 0;
            this.showImage(this.currentIndex, this.thumbnails[0]);
          }
          if (this.thumbnails.length > 0) {
            this.showReorder = true;
            console.log("yes image", this.thumbnails[0]);
            this.currentIndex = 0;
            this.showImage(this.currentIndex, this.thumbnails[0]);
          }
          console.log("thumbnailPath", this.thumbnails);
          //Reclassify multiplepages after create version that time change version and select borrower
          // else {
          //   this.boMultiReclasShow = true;
          //   this.versionMultiReclasShow = true;
          // }
        }
      },
      error: (err: any) => {
        console.log("Error", err);
      },
      complete: () => {
        if (this.thumbnails.length == 0) {
          this.pageNo = StringDataType.EMPTY;
          this.showReorder = false;
          this.isNoPage = true;
          this.showDocumentView = false;
          this.showSplashDocTypeView = false;
          this.showReclassifyMultiplePages = false;
          this.showSplashPageView = false;
          this.showDocumentListView = false;
          this.showDocumentList = true;
        }
      },
    });
  }

  selectThumbnailsForReclassifyMultiple(
    loandId: string,
    documentId: string,
    borrowerId?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showNoImage?: boolean
  ) {
    this.thumbnails = [];
    this.documentService.getPageThumbnailList(loandId, documentId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.documentDetails) {
          // Map the response to convert `thumbnailPath` directly for each document
          this.thumbnails = res.data.documentDetails.map((doc: any) => {
            return {
              ...doc,
              thumbnailPath: `data:image/png;base64,${doc.thumbnailPath}`, // Prepend base64 header
            };
          });
          if (!this.multiReclassification) {
            this.pageNo = (this.currentIndex + 1).toString();
            this.showImage(this.currentIndex);
          }
          console.log("thumbnailPath", this.thumbnails);
        }
      },
      error: (err: any) => {
        console.log("Error", err);
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectThumbnailsForReorder(loandId: string, documentId: string, borrowerId?: string, showNoImage?: boolean) {
    this.thumbnails = [];
    this.documentService.getPageThumbnailList(loandId, documentId, borrowerId)?.subscribe({
      next: (res: any) => {
        if (res?.data?.documentDetails) {
          // Map the response to convert `thumbnailPath` directly for each document
          this.thumbnails = res.data.documentDetails.map((doc: any) => {
            return {
              ...doc,
              thumbnailPath: `data:image/png;base64,${doc.thumbnailPath}`, // Prepend base64 header
            };
          });
        }
      },
      error: (err: any) => {
        console.log("Error", err);
      },
    });
  }

  onSaveProgress() {
    this.onOptionSelect();
    const resetSaveChanges: SaveProcess = {
      loanId: this.loandId, // Set this dynamically if needed
    };
    this.documentService.saveProgress(resetSaveChanges)?.subscribe({
      next: (response) => {
        if (response) {
          sessionStorage.clear();
          this.countSaveChangesforUndo = 0;
          this.isSubmitDisabled = false;
          this.notificationService.showSuccess("Progress saved successfully");
        }
      },
      error: (error) => {
        console.error("Error while save the  changes:", error);
        if (error.message) {
          //this.notifyErrorMsg = true;
          this.notificationService.showError("chnages already reverted");
        } else {
          // Handle error here
        }
      },
    });
  }

  onDocumentSelect(documentType: DocumentTypesMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.documentVersionList = [];
      this.currentOpenVersion = null;
      this.isActive = true;
      this.multiReclassification = false;
      this.versionFileName = StringDataType.EMPTY;
      this.versionReclasShow = false;
      this.selectedVersionBoName = StringDataType.EMPTY;
      this.documentTypeId = documentType.code;
      // This ensures that the event is triggered by user interaction
      if (documentType.versioning_Scope === "borrower") {
        this.borrowerShow = true;
        this.showSplashDocTypeView = false;
        this.currentIndex = 0;
        this.pageNo = "";
        this.isActive = true;
        this.showDocumentList = false;
        this.showSplashPageView = true;
        this.showDocumentListView = false;
        this.showReclassifyMultiplePages = false;
        this.showDocumentList = false;
        this.showDocumentView = false;
        // this.onOptionSelect();
        this.isNoPage = false;
        this.getBorrowerList(this.loandId, documentType.code);
      } else {
        this.borrowerShow = false;
        this.showSplashDocTypeView = false;
        this.showSplashPageView = false;
        this.showDocumentList = true;
        this.showDocumentView = true;
        //this.isNoPage = false;
        this.mainBorrowerId = StringDataType.EMPTY;
        this.borrowerId = StringDataType.EMPTY;
        // need to document version api here
        this.getSelectDocTypeVersionList(this.loandId, documentType.code);
      }
      this.selectedDocDesc = documentType.description; // Capture the selected document code
    }
  }

  // any change in borrower list
  onBorrowerSelect(borrowerType: BorrowerMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.isActive = true;
      //console.log("Borrower ID", borrowerType.id);
      // get borrowe id h
      this.borrowerId = borrowerType.id;
      this.mainBorrowerId = borrowerType.id;
      this.selectedVersionBoName = borrowerType.versioning_Borrower_Name; // Capture the selected document code
      this.getSelectDocTypeVersionList(this.loandId, this.documentTypeId, borrowerType.id);
    }
  }

  // any change in Borrower reclassify list
  onReclassifyBorrowerSelect(borrowerType: BorrowerMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.isActive = true;
      // This ensures that the event is triggered by user interaction
      this.borrowerId = borrowerType.id ? borrowerType.id : StringDataType.EMPTY;
      this.selectedVersionReclsBoName = borrowerType.versioning_Borrower_Name; // Capture the selected document code
      // called getDocumentVersion for borrowelist
      this.versionReclasShow = true;
      this.getVersionsList(this.documentTypeCode, this.loandId, this.borrowerId);
      //console.log('Selected selectedVersionReclsBoName:', this.selectedVersionReclsBoName);
    }
  }

  // any change in Borrower Reorder reclassify list
  onReorderReclassifyBorrowerSelect(borrowerType: BorrowerMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      // This ensures that the event is triggered by user interaction
      this.borrowerId = borrowerType.id ? borrowerType.id : StringDataType.EMPTY;

      this.saveChangesArray.forEach((obj) => {
        obj.borrowerId = this.borrowerId;
      });
      this.selectedVersionMultiReclsBoName = borrowerType.versioning_Borrower_Name; // Capture the selected document code
      // called getDocumentVersion for borrowerlist
      this.versionReclasShow = false;
      this.versionMultiReclasShow = true;
      this.isSaveMultipleData = true;
      this.getVersionsList(this.documentTypeCode, this.loandId, this.borrowerId);
      //console.log('Selected selectedVersionMultiReclsBoName:', this.selectedVersionMultiReclsBoName);
    }
  }

  // Method for selecting a document in "Reclassify To"
  onReclassifySelect(documentType: DocumentTypesMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.boReclasShow = false;
      this.versionReclasShow = false;
      this.selectedReclsVersionName = StringDataType.EMPTY;
      this.selectedVersionReclsBoName = StringDataType.EMPTY;
      this.selectedDocumentTypeCode = documentType.code;
      this.versionMultiReclasShow = false;
      this.isDisabledReclass = true;
      // This ensures that the event is triggered by user interaction
      if (documentType.versioning_Scope === "borrower") {
        this.boReclasShow = true;
        this.versionReclasShow = false;
        // we get documentTypeCode and borrowerId call api
        this.getBorrowerList(this.loandId, documentType.code);
        this.selectedReclsDesc = documentType.description;
        this.documentTypeCode = documentType.code;
      } else {
        this.borrowerId = StringDataType.EMPTY;
        this.boReclasShow = false;
        this.selectedReclsDesc = documentType.description;
        this.documentTypeCode = documentType.code;
        this.versionReclasShow = true; // change version show in relcclassify pages
        // called getDocumentVersion
        this.getVersionsList(this.documentTypeCode, this.loandId);
      }
    }
  }

  // Method for selecting a document in " Reorder Reclassify To"
  onReorderReclassifySelect(documentType: DocumentTypesMaster, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.versionMultiReclasShow = false;
      this.boMultiReclasShow = false;
      this.isSaveMultipleData = true;
      this.selectedMultiReclsVerName = StringDataType.EMPTY;
      this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
      this.selectedDocumentTypeCode = documentType.code;
      // Update each saveChangeObj in saveChangesArray with new documentType
      this.saveChangesArray.forEach((obj) => {
        obj.documentType = this.selectedDocumentTypeCode;
      });
      // This ensures that the event is triggered by user interaction
      this.selectedMultiReclsDesc = documentType.description;
      this.documentTypeCode = documentType.code;
      //console.log("Reclassified To Document type ID:", this.documentTypeCode);
      if (documentType.versioning_Scope === "borrower") {
        this.boMultiReclasShow = true;
        this.versionMultiReclasShow = false;
        // we get documentTypeCode and borrowerId call api
        this.getBorrowerList(this.loandId, documentType.code);
      } else {
        this.boMultiReclasShow = false;
        this.versionReclasShow = true;
        this.versionMultiReclasShow = true;
        //console.log("we dont have documentTypeCode and borrowerId");
        // called getDocumentVersion
        this.getVersionsList(this.documentTypeCode, this.loandId);
      }
    }
  }

  // Method for Reclassify Multiple Pages
  onSortListClick(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.isReclassifyFormMultiple = true;
    console.log("this.showReorder", this.showReorder);
    if ((this.selectedReclsDesc && this.selectedReclsVersionName) || this.selectedVersionReclsBoName) {
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else {
      //dashboard code
      this.isActive = false;
      this.isOpen = !this.isOpen;
      this.isMultiDocumentOpen = true;
      this.showDocumentView = false;
      this.showDocumentList = true;
      this.showDocumentListView = false;
      this.reclassification = false;
      this.isNoPage = false;
      this.showSplashPageView = false;
      this.showReclassifyMultiplePages = true;
      this.isButtonVisible = false;
      this.CloseReclassifyDialog();
      // this.getVersionsList();
    }
  }

  closePage() {
    //this.imageShow = false;
    this.showDocumentView = false;
    this.showDocumentList = true;
    this.showDocumentListView = true;
    //this.showReclassifyMultiplePages = true;
    this.imageShow = true;
    this.showOneDocument = !this.showOneDocument;
  }

  onReorderClick(enterAnimationDuration: string, exitAnimationDuration: string) {
    if ((this.selectedReclsDesc && this.selectedReclsVersionName) || this.selectedVersionReclsBoName) {
      this.isReorderClicked = true;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else if (
      (this.selectedMultiReclsDesc && this.selectedMultiReclsVerName) ||
      this.selectedVersionMultiReclsBoName
    ) {
      this.isReorderYes = true;
      this.changeTrackDialog(enterAnimationDuration, exitAnimationDuration);
    } else {
      this.showReorder = true;
      this.isActive = false;
      this.imageShow = false;
      this.showDocumentView = false;
      this.showDocumentList = true;
      this.showDocumentListView = true;
      this.isButtonVisible = true;
      this.showReclassifyMultiplePages = false;
      this.isReorderMultipageOpen = true;
    }
  }

  // Reclassify change version
  onReclassifyChangeVersions(documentType: any, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      //this.selectedVersionCode = version.code;
      // This ensures that the event is triggered by user interaction
      this.selectedReclsVersionName = documentType.description;
      this.VersionId = documentType.code;
      //console.log("Reclassified To Document type ID:", this.documentTypeCode);
      this.isDisabledReclass = false;
      // call getversionList()
      //this.getVersionsList(documentType.);
    }
  }

  // Reorder Reclassify change version
  onReorderReclassifyChangeVersions(documentType: any, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.isSaveMultipleData = false;
      // This ensures that the event is triggered by user interaction
      this.selectedMultiReclsVerName = documentType.description;
      this.VersionId = documentType.code;
      // Update each saveChangeObj in saveChangesArray with new version
      this.saveChangesArray.forEach((obj) => {
        obj.version = this.VersionId;
      });
      //console.log("Reclassified To Document type ID:", this.documentTypeCode);
    }
  }

  // create new version
  createNewVersion(data: string) {
    if (data) {
      this.saveChangesShow = true;
      //const newdocumentTypeVersion = data;
      //console.log("create new version", data);
      const version: CreateVersion = {
        loanId: this.loandId, // Set this dynamically if needed
        documentTypeCode: this.documentTypeCode, // Set this dynamically if needed
        documentVersionCode: data,
        borrowerId: this.borrowerId, // Set this dynamically if needed
      };
      // Call the service and handle the result
      this.documentService.createVersion(version.loanId, version)?.subscribe({
        next: (response) => {
          if (response.message) {
            //this.notifySuccessMsg = true;re
            // called getversionList()
            this.versionReclasShow = true;
            this.versionMultiReclasShow = true;
            this.getVersionsList(version.documentTypeCode, version.loanId, version.borrowerId);
            this.getSelectDocTypeVersionList(this.loandId, this.documentTypeId, this.borrowerId);
            this.notificationService.showSuccess("Version created successfully");
          }
          //console.log("Version created successfully:", response);
          // Handle successful response here
        },
        error: (error) => {
          console.error("Error creating version:", error);
          if (error.message) {
            //this.notifyErrorMsg = true;
            this.notificationService.showError("version already present");
          } else {
            // Handle error here
          }
        },
      });
    }
  }

  // create new version
  createNewVersionMultiple(data: string) {
    if (data) {
      this.saveChangesShow = true;
      //const newdocumentTypeVersion = data;
      //console.log("create new version", data);
      const version: CreateVersion = {
        loanId: this.loandId, // Set this dynamically if needed
        documentTypeCode: this.documentTypeCode, // Set this dynamically if needed
        documentVersionCode: data,
        borrowerId: this.borrowerId, // Set this dynamically if needed
      };
      // Call the service and handle the result
      this.documentService.createVersion(version.loanId, version)?.subscribe({
        next: (response) => {
          if (response.message) {
            //this.notifySuccessMsg = true;re
            // called getversionList()
            this.versionReclasShow = true;
            this.versionMultiReclasShow = true;
            this.getVersionsList(version.documentTypeCode, version.loanId, version.borrowerId);
            this.getSelectDocTypeVersionListofReclassify(this.loandId, this.documentTypeId, this.mainBorrowerId);
            this.notificationService.showSuccess("Version created successfully");
          }
          //console.log("Version created successfully:", response);
          // Handle successful response here
        },
        error: (error) => {
          console.error("Error creating version:", error);
          if (error.message) {
            //this.notifyErrorMsg = true;
            this.notificationService.showError("version already present");
          } else {
            // Handle error here
          }
        },
      });
    }
  }

  // create new borrower
  createNewBorrower(data: any) {
    if (data) {
      console.log("new Borrower name", data);
      const newBorrower: CreateBorrowerVersion = {
        borrowerName: data,
      };
      this.documentService.createBorrowerVersion(this.loandId, newBorrower)?.subscribe({
        next: (response) => {
          if (response.message) {
            this.getBorrowerList(this.loandId, this.documentTypeCode);
            this.notificationService.showSuccess("New Borrower created successfully");
          }
        },
        error: (error) => {
          console.error("Error creating version:", error);
        },
      });
    }
  }

  resetSaveChanges() {
    this.onOptionSelect();
    const resetSaveChanges: ResetChanges = {
      loanId: this.loandId, // Set this dynamically if needed
    };
    this.documentService.resetChanges(resetSaveChanges)?.subscribe({
      next: (response) => {
        if (response) {
          this.notificationService.showSuccess("Reset successfully");
          window.location.reload();
          sessionStorage.clear();
          this.countSaveChangesforUndo = 0;
          this.reclassificationSuccess = false;
          this.reorderSuccess = false;
        }
      },
      error: (error) => {
        console.error("Error reveting the changes:", error);
        if (error.message) {
          //this.notifyErrorMsg = true;
          this.notificationService.showError("changes already reverted");
        } else {
          // Handle error here
        }
      },
    });
  }

  undoChanges() {
    this.onOptionSelect();
    const resetSaveChanges: ResetChanges = {
      loanId: this.loandId, // Set this dynamically if needed
    };
    this.documentService.undoSaveChanges(resetSaveChanges)?.subscribe({
      next: (response) => {
        if (response) {
          this.showDocumentList = true;
          this.showSplashDocTypeView = false;
          this.showSplashPageView = false;
          this.showReclassifyMultiplePages = true;
          this.getDocumentTypeListData(this.isActive);
          //this.getSelectDocTypeVersionList(this.loandId, this.documentType.code);
          this.selectThumbnails(this.loandId, this.documentID, this.mainBorrowerId, this.showNoImage);
          this.notificationService.showSuccess("Recent change has been undone successfully.");
        }
      },
      error: (error) => {
        // console.error("Error reveting the changes:", error);
        if (error.message) {
          //this.notifyErrorMsg = true;
          this.notificationService.showError("changes already undon");
        } else {
          // Handle error here
        }
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeElementFromSaveChanges(saveChangesArray: saveChanges[]) {
    // Remove the object from saveChangesArray
    this.saveChangesArray = this.saveChangesArray.filter(
      (obj) => obj.pageIndex == 0 && obj.updatedPageNumber != obj.currentPageNumber
    );
    console.log("this.saveChangesArray", this.saveChangesArray);
  }

  private buildSaveOrderArray() {
    this.saveOrderArray = []; // Initialize to avoid appending to an old array
    this.thumbnails.forEach((e: any, index: number) => {
      if (index >= 0) {
        const reorderObj = {
          pageId: this.thumbnails[index].pageId,
          currentDocumentId: this.documentID,
          currentPageNumber: 0,
          updatedDocumentId: this.documentID,
          updatedPageNumber: this.thumbnails[index].pageNumber,
          loanId: this.loandId,
          userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          documentType: null,
          version: null,
          borrowerId: this.borrowerId ?? null,
          isAllowed: 2,
        };
        this.saveOrderArray.push(reorderObj);
      }
    });
    console.log("this.saveOrderArray", this.saveOrderArray);
  }

  saveReorderChanges() {
    this.saveReorder = true;
    this.reorderSuccess = true;
    this.buildSaveOrderArray(); // Use the private method
    // Remove the object from saveChangesArray
    this.removeElementFromSaveChanges(this.saveOrderArray);
    console.log("this.saveChangesArray", this.saveChangesArray);
    this.saveChangesShow = true;
    if (this.saveOrderArray.length > 0) {
      this.saveChangesofReorder(this.saveOrderArray);
      //this.selectThumbnailsForReorder(this.loandId, this.documentID, this.borrowerId);
      this.selectedItems.clear(); // Clear previous selections
    }
    this.isActive = false;
    this.selectedItems.clear(); // Clear previous selections
    this.showReorder = true;
  }

  saveAndExitReorderChanges() {
    if (this.showOneDocument) {
      this.closePage();
    }
    console.log("array object", this.saveOrderArray);
    this.reorderSuccess = true;
    this.saveReorder = false;
    if (this.formattedPageSelection != StringDataType.EMPTY) {
      this.buildSaveOrderArray(); // Use the private method
      // Remove the object from saveChangesArray
      this.removeElementFromSaveChanges(this.saveOrderArray);
      if (this.saveOrderArray.length > 0) {
        this.saveOrSaveExistReorder(this.saveOrderArray);
      } else {
        this.isActive = true;
        //console.log("this.saveChangesArray is empty please select page");
      }
      this.selectedPanelOpen(this.documentVersionList[0]);
      this.showReorder = true;
    }
    this.selectedItems.clear(); // Clear previous selections
    this.showReorder = true;
    this.formattedPageSelection = StringDataType.EMPTY;
    this.saveChangesArray = [];
    this.selectedReclassifyItems.clear();
    this.selectedItems.clear();
    this.isReorderChange = true;
    this.showImage((this.currentIndex = 0));
  }

  // save multiple pages For Reclassification
  saveMultiReclassifyChanges() {
    this.reclassificationSuccess = true;
    this.saveChangesShow = true;
    if (this.saveChangesArray.length > 0) {
      this.saveChanges(this.saveChangesArray);
      this.selectedItems.clear(); // Clear previous selections
    } else {
      this.isActive = true;
      this.notificationService.showError("Please Select Any Page For Multiple Reclassification");
      //console.log("this.saveChangesArray is empty please select page");
    }
    this.selectedItems.clear(); // Clear previous selections
    this.showReorder = true;
  }

  saveChanges(saveChangeObj: saveChanges[]) {
    this.onOptionSelect();
    // Create the `saveChanges` array and add `saveChangeObj`
    //const saveChangesArray: saveChanges[] = [saveChangeObj];
    this.documentService.saveChanges(saveChangeObj)?.subscribe({
      next: (response: any) => {
        if (response) {
          this.showNoImage = true;
          this.onOptionSelect();
          // Called undo functionality
          this.saveChangesforUndo();
          if (this.reclassification) {
            this.notificationService.showSuccess("Reclassification saved.Submit to finalize.");
          } else if (this.multiReclassification) {
            this.notificationService.showSuccess("Multiple Reclassification saved.Submit to finalize.");
          } else {
            this.notificationService.showSuccess("Pages are re-organized and saved sucessfully.");
          }
        }
      },
      error: (error) => {
        console.error("Error while save the changes:", error);
        //this.notificationService.showError(error.error.message);
        if (error.message) {
          //this.notifyErrorMsg = true;
          if (this.saveReorder) {
            this.notificationService.showError("An error occurred while saving your changes");
          }
        }
      },
      complete: () => {
        // i need to called thumnail api again
        this.selectThumbnailsForReclassifyMultiple(
          this.loandId,
          this.documentID,
          this.mainBorrowerId,
          this.showNoImage
        );
        // need to remove object from array
        this.saveChangesArray = [];
        this.isActive = false;
        this.clearModel();
      },
    });
  }

  saveChangesofReorder(saveChangeObj: saveChanges[]) {
    this.onOptionSelect();
    // Create the `saveChanges` array and add `saveChangeObj`
    //const saveChangesArray: saveChanges[] = [saveChangeObj];
    this.documentService.saveChanges(saveChangeObj)?.subscribe({
      next: (response: any) => {
        if (response) {
          this.showNoImage = true;
          this.onOptionSelect();
          // Called undo functionality
          this.saveChangesforUndo();
          this.notificationService.showSuccess("Pages are re-organized and saved sucessfully.");
        }
      },
      error: (error) => {
        console.error("Error while save the changes:", error);
        //this.notificationService.showError(error.error.message);
        if (error.message) {
          //this.notifyErrorMsg = true;
          if (this.saveReorder) {
            this.notificationService.showError("An error occurred while saving your changes");
          }
        }
      },
      complete: () => {
        // after complete called thumbnail api again
        this.selectThumbnailsForReorder(this.loandId, this.documentID, this.borrowerId);
        // need to remove object from array
        this.saveChangesArray = [];
        this.isActive = false;
        this.clearModel();
      },
    });
  }

  saveOrSaveExistReorder(saveChangeObj: saveChanges[]) {
    this.onOptionSelect();
    // Create the `saveChanges` array and add `saveChangeObj`
    //const saveChangesArray: saveChanges[] = [saveChangeObj];
    this.documentService.saveChanges(saveChangeObj)?.subscribe({
      next: (response: any) => {
        if (response) {
          this.notificationService.showSuccess("Pages are re-organized and saved sucessfully.");
          this.showNoImage = true;
          this.onOptionSelect();
          // Called undo functionality
          this.saveChangesforUndo();
        }
      },
      error: (error) => {
        console.error("Error while save the changes:", error);
        //this.notificationService.showError(error.error.message);
        if (error.message) {
          //this.notifyErrorMsg = true;
          if (this.saveReorder) {
            this.notificationService.showError("An error occurred while saving your changes");
          }
        }
      },
      complete: () => {
        // need to remove object from array
        this.selectThumbnailsForReorder(this.loandId, this.documentID, this.borrowerId);
        this.saveChangesArray = [];
        this.isActive = false;
        this.clearModel();
      },
    });
  }

  onsubmit() {
    this.notifySuccessBadge = true;
  }

  // Resets the form data to default values
  resetReclassifyForm() {
    if (
      (this.isReclassifyFormMultiple && this.selectedReclsDesc && this.selectedReclsVersionName) ||
      this.selectedVersionReclsBoName
    ) {
      this.selectedReclsDesc = StringDataType.EMPTY;
      this.selectedVersionReclsBoName = StringDataType.EMPTY;
      this.selectedReclsVersionName = StringDataType.EMPTY;
      this.isOpen = !this.isOpen;
      this.showDocumentView = false;
      this.showDocumentList = true;
      this.showDocumentListView = false;
      this.showReclassifyMultiplePages = true;
      this.isButtonVisible = false;
      this.isReclassifyFormMultiple = false;
      // this.getVersionsList();
    } else if (
      (this.isReorderClicked && this.selectedReclsDesc && this.selectedReclsVersionName) ||
      this.selectedVersionReclsBoName
    ) {
      this.selectedReclsDesc = "";
      this.selectedVersionReclsBoName = "";
      this.selectedReclsVersionName = "";
      this.isOpen = !this.isOpen;
      //reorder page conditions
      this.imageShow = false;
      this.showDocumentView = false;
      this.showDocumentList = true;
      this.showDocumentListView = true;
      this.isButtonVisible = !this.isButtonVisible;
      this.isReorderClicked = false;
    } else if (
      (this.isReorderYes && this.selectedMultiReclsDesc && this.selectedMultiReclsVerName) ||
      this.selectedVersionMultiReclsBoName
    ) {
      this.selectedMultiReclsDesc = StringDataType.EMPTY;
      this.selectedMultiReclsVerName = StringDataType.EMPTY;
      this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
      this.imageShow = false;
      this.showDocumentView = false;
      this.showDocumentList = true;
      this.showDocumentListView = true;
      this.isButtonVisible = !this.isButtonVisible;
      this.isReorderYes = false;
    } else if (
      (this.isReclassifyForm && this.selectedMultiReclsDesc && this.selectedMultiReclsVerName) ||
      this.selectedVersionMultiReclsBoName
    ) {
      //this.pageSelect = [];
      this.selectedMultiReclsDesc = StringDataType.EMPTY;
      this.selectedMultiReclsVerName = StringDataType.EMPTY;
      this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
      this.isOpen = !this.isOpen;
      this.getDocumentTypeListData(this.isActive);
      this.isReclassifyForm = false;
    } else if (
      (this.isDocumentclicked && this.selectedReclsDesc && this.selectedReclsVersionName) ||
      this.selectedVersionReclsBoName
    ) {
      this.selectedReclsDesc = StringDataType.EMPTY;
      this.selectedVersionReclsBoName = StringDataType.EMPTY;
      this.selectedReclsVersionName = StringDataType.EMPTY;
      this.isOpen = !this.isOpen;
      this.getDocumentTypeListData(this.isActive);
      this.isDocumentclicked = false;
      // this.getVersionsList();
    }
    //isDocumentMultiClicked
    else if (
      (this.isDocumentMultiClicked && this.selectedMultiReclsDesc && this.selectedMultiReclsVerName) ||
      this.selectedVersionMultiReclsBoName
    ) {
      this.selectedMultiReclsDesc = StringDataType.EMPTY;
      this.selectedMultiReclsVerName = StringDataType.EMPTY;
      this.selectedVersionMultiReclsBoName = StringDataType.EMPTY;
      this.getDocumentTypeListData(this.isActive);
      this.isDocumentMultiClicked = false;
    } else if (this.isDocumentSelectedPagesRemove && this.pageSelect.length > 0) {
      this.pageSelect = [];
      this.isDocumentSelectedPagesRemove = false;
    } else if (this.isReclassifyPageReorder && this.pageSelect.length > 0) {
      this.pageSelect = [];
      this.isOpen = !this.isOpen;
      this.getDocumentTypeListData(this.isActive);
      this.isReclassifyPageReorder = false;
    }
  }

  clearReclassifyData() {
    this.isReclassifyFormMultiple ? (this.showReorder = false) : (this.showReorder = true);
    this.formattedPageSelection = StringDataType.EMPTY;
    this.selectedReclassifyItems.clear();
    this.selectedItems.clear();
    this.saveChangesArray = [];
    this.isActive = true;
    this.selectedReclsDesc = StringDataType.EMPTY;
    this.selectedVersionReclsBoName = StringDataType.EMPTY;
    this.selectedReclsVersionName = StringDataType.EMPTY;
    this.boReclasShow = false;
    this.versionReclasShow = false;
    this.filteredReclsTypes = [];
    this.filterReclsBorrowerTypes = [];
    this.filteredReclsVersionTypes = [];
    console.log("Reclassify data cleared.");
  }

  CloseReclassifyDialog() {
    // Avoid redundant closures
    this.clearReclassifyData();
    this.dialog.closeAll();
  }

  getSelectedItems() {
    // const orderedItemsSignal = this.orderedItems();
    return Array.from(this.selectedItems).map((index) => this.thumbnails[index - 1]);
  }

  onDragMoved(event: CdkDragMove) {
    // Get the container's scrollable area
    const container = document.querySelector(".img-list-view");

    if (container) {
      const containerTop = container.getBoundingClientRect().top;
      const containerBottom = container.getBoundingClientRect().bottom;

      // Check if the drag is near the top or bottom
      if (event.pointerPosition.y < containerTop + 50) {
        // If near the top, scroll up
        container.scrollBy(0, -20);
      } else if (event.pointerPosition.y > containerBottom - 50) {
        // If near the bottom, scroll down
        container.scrollBy(0, 20);
      }
    }
  }

  postSubmit() {
    this.onOptionSelect();
    const submitChanges: SubmitChanges = {
      loanId: this.loandId,
    };

    this.documentService.updateStatusSubmit(submitChanges)?.subscribe({
      next: (response: any) => {
        console.log(response);
        if (response) {
          this.notificationService.showSuccess("Submitted Successfully");
        }
      },
      error: () => {
        this.notificationService.showError("Error occur while submiting data.");
      },
    });
  }
}
