export class saveChanges {
  pageIndex = 0;
  pageId: string | undefined;
  currentDocumentId!: string;
  currentPageNumber: number | undefined;
  updatedDocumentId: string | undefined | null;
  updatedPageNumber = 0;
  loanId: string | undefined;
  userId: string | undefined;
  documentType!: string | undefined | null;
  version!: string | undefined | null;
  borrowerId!: string | undefined | null;
  isAllowed = 0;
}
