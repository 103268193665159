import { environment } from "../../../environments/environment";

export class PortfolioFilesStatus {
  public static readonly Completed = "Completed";
  public static readonly InProcess = "InProcess";
}

export interface ApiGridResponseModel<T> {
  status: boolean;
  message: string;
  data: T;
  count: number;
}

export enum type {
  all,
  available,
}

export class rdDocumentTypeVersion {
  code!: string;
  description!: string;
}

// string-constants.ts
export const StringDataType = {
  EMPTY: "",
};

export class TableNames {
  public static readonly Users = "Users";
  public static readonly Customers = "Customers";
  public static readonly CustomerTypes = "CustomerTypes";
  public static readonly ConditionCategories = "ConditionCategories";
  public static readonly ConditionDetails = "ConditionDetails";
  public static readonly PortfolioFilesTable = "PortfolioFilesTable";
  public static readonly DocumentTypeMaster = "DocumentTypeMaster";
  public static readonly DataExtractionFieldMaster = "DataExtractionFieldMaster";
  public static readonly PortfolioOtherFilesTable = "PortfolioOtherFilesTable";
  public static readonly DataExtractionValueGroupTable = "DataExtractionValueGroupTable";
  public static readonly DataExtractionValueTable = "DataExtractionValueTable";
  public static readonly PortfolioConditionTable = "PortfolioConditionTable";
  public static readonly ConditionDetailsKeyTable = "ConditionDetailsKeyTable";
  public static readonly ErrorLogTable = "ErrorLogTable";
  public static readonly SFTPDetailTable = "SFTPDetailTable";
  public static readonly RuleEngineTable = "RuleEngineTable";
}

export const BASE_URL = environment.base_url;
export const Email = "admin@localhost.com";
export const Pass = "P@ssword1";
